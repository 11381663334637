.c-form,
.c-form__item,
.c-form__label,
.c-form__input {
  display: block;
  width: 100%;
}

.c-form__item--inline {
  display: inline;
}

.c-form__item--inline-all {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin: -0.5em;

  & > *,
  & .c-form__label {
    width: auto;
    flex: 0 1 auto;
    margin: 0.5em;
  }

  & .c-form__label {
    white-space: nowrap;
  }
}

.c-form__item--checkboxinput,
.c-form__item--radioselect {
  margin: 1em 0 0.5em;
}

.c-form__label {
  margin: 1.5em 0 0.5em;
  font-weight: 400;
  font-size: 1em;
}

.c-form__item--required .c-form__label::after {
  content: " *";
}

.c-form__item--required .c-form__item:not(.c-form__item--required) > .c-form__label::after {
  display: none;
}

.c-form__input--text,
.c-form__input--urlinput,
.c-form__input--search,
.c-form__input--number,
.c-form__input--email,
.c-form__input--textarea {
  width: 100%;
  padding: 0.25em;
  border: 1px solid #929292;
  margin: 0.5em 0;
  -webkit-appearance: none;

  &:focus {
    outline: 2px dotted var(--c-teal);
  }

  &:read-only {
    background-color: var(--c-grey-6);
  }
}

.c-form__input--search {
  border-radius: 0.5em;
  padding: 0.25em;
}

.c-form__input--number {
  max-width: 8em;
}

.c-form--error input,
.c-form--error textarea {
  border-color: var(--errorColor);
}

.c-form__input--checkbox,
.c-form__input--radio,
.c-form__label--checkbox,
.c-form__label--radio {
  display: inline-block;
  width: auto;
}

.c-form__input--checkbox {
  margin: 0 0.5em;
}

.c-form__input--radio {
  margin-left: 0.5em;
}

.c-form__file {
  display: block;

  &:not(:empty)::before {
    content: "File chosen: ";
    font-weight: 700;
  }
}

.c-form__item--fileinput {
  & .c-form__label {
    display: inline-block;
    width: auto;
    padding: 0.5em 0.5em 0.75em;
    /* border: 2px solid var(--c-grey-3); */
    border: 0;
    margin: 1em 0;
    background: var(--c-purple-5);
    color: var(--c-purple-5-b);
    border-radius: 0.3em;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 -0.25em 0 rgba(0, 0, 0, 0.25),
      0 0.25em 0.25em rgba(0, 0, 0, 0.05);
    font-weight: 400;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.25);
    transition: background-color 0.15s ease-in, box-shadow 0.15s ease-in;
    vertical-align: middle;

    &:hover,
    &:focus {
      background-color: var(--c-purple-6);
      color: var(--c-purple-6-b);
    }

    &:active {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 2px 0 hsla(0, 0%, 100%, 0.1),
        inset 0 0.25em 0.5em rgba(0, 0, 0, 0.05);
    }
  }
}

.c-form__input--fileinput {
  position: absolute;
  display: inherit;
  clip: rect(0, 0, 0, 0);
}

.c-form__item--select select {
  & {
    display: inline-block;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 400;
    color: #444;
    line-height: 1.3;
    padding: 0.5em 1.5em 0.5em 0.5em;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #fff 100%);
    background-position: right 0.7em top 50%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: 0.65em auto, 100%;
    text-overflow: ellipsis;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: none;
    color: #222;
    outline: 2px dotted var(--c-teal);
  }
  & option {
    font-weight: normal;
    width: 100%;
    overflow-x: auto;
  }

  & option::selection,
  & option:checked,
  & option:hover {
    box-shadow: 0 0 10px 100px var(--c-teal-7) inset !important;
  }

  &::selection {
    background-color: red;
  }
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="radio"]:not(:checked) + span,
[type="radio"]:checked + span {
  position: relative;
  display: inline-block;
  height: 25px;
  padding-left: 35px;
  cursor: pointer;
  line-height: 25px;
  -webkit-transition: 0.28s ease;
  transition: 0.28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type="radio"] + span::before,
[type="radio"] + span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: 0.28s ease;
  transition: 0.28s ease;
}

[type="radio"]:not(:checked) + span::before,
[type="radio"]:not(:checked) + span::after,
[type="radio"]:checked + span::before,
[type="radio"]:checked + span::after,
[type="radio"].with-gap:checked + span::before,
[type="radio"].with-gap:checked + span::after {
  border-radius: 50%;
}

[type="radio"]:not(:checked) + span::before,
[type="radio"]:not(:checked) + span::after {
  border: 2px solid #5a5a5a;
}

[type="radio"]:not(:checked) + span::after {
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + span::before {
  border: 2px solid transparent;
}

[type="radio"]:checked + span::after,
[type="radio"].with-gap:checked + span::before,
[type="radio"].with-gap:checked + span::after {
  border: 2px solid #26a69a;
}

[type="radio"]:checked + span::after,
[type="radio"].with-gap:checked + span::after {
  background-color: #26a69a;
}

[type="radio"]:checked + span::after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

[type="radio"].with-gap:checked + span::after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

[type="radio"].tabbed:focus + span::before {
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}

[type="radio"].with-gap:disabled:checked + span::before {
  border: 2px solid rgba(0, 0, 0, 0.42);
}

[type="radio"].with-gap:disabled:checked + span::after {
  border: none;
  background-color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled:not(:checked) + span::before,
[type="radio"]:disabled:checked + span::before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled + span {
  color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled:not(:checked) + span::before {
  border-color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled:checked + span::after {
  background-color: rgba(0, 0, 0, 0.42);
  border-color: #949494;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="checkbox"] + span:not(.lever) {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type="radio"]:focus + span:not(.lever),
[type="checkbox"]:focus + span:not(.lever) {
  outline: 2px dotted var(--c-teal-4);
}

[type="checkbox"] + span:not(.lever)::before,
[type="checkbox"]:not(.filled-in) + span:not(.lever)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 3px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

[type="checkbox"]:not(.filled-in) + span:not(.lever)::after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="checkbox"]:not(:checked):disabled + span:not(.lever)::before {
  border: none;
  background-color: rgba(0, 0, 0, 0.42);
}

[type="checkbox"].tabbed:focus + span:not(.lever)::after {
  -webkit-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"]:checked + span:not(.lever):before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"]:checked:disabled + span::before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

[type="checkbox"]:indeterminate + span:not(.lever):before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"]:indeterminate:disabled + span:not(.lever):before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  background-color: transparent;
}

[type="checkbox"].filled-in + span:not(.lever):after {
  border-radius: 2px;
}

[type="checkbox"].filled-in + span:not(.lever):before,
[type="checkbox"].filled-in + span:not(.lever):after {
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s,
    top 0.2s 0.1s, left 0.2s 0.1s;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s,
    left 0.2s 0.1s;
  z-index: 1;
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):before {
  width: 0;
  height: 0;
  border: 3px solid transparent !important;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0px;
  z-index: 0;
}

[type="checkbox"].filled-in:checked + span:not(.lever):before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}

[type="checkbox"].filled-in.tabbed:focus + span:not(.lever):after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a;
}

[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border-color: transparent;
  background-color: #949494;
}

[type="checkbox"].filled-in:disabled:checked + span:not(.lever):before {
  background-color: transparent;
}

[type="checkbox"].filled-in:disabled:checked + span:not(.lever):after {
  background-color: #949494;
  border-color: #949494;
}

.t-formset .c-form__label {
  position: absolute;
  display: inherit;
  clip: rect(0, 0, 0, 0);
}
