.c-touchdown {
  display: flex;
  flex-flow: column nowrap;
  line-height: 1.3;
  text-align: center;
}

.c-touchdown__title {
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--c-grey-3);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.l-work-count .c-touchdown__title,
.c-touchdown__data {
  display: block;
  padding: 0 0.5rem 0.5rem;
  text-align: center;
}

.c-touchdown__data {
  padding-top: 0.5rem;
}
