.c-box {
  width: 100%;
  padding: var(--container-padx);
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* background: var(--c-grey-7); */
  /* background: #eef0f4; */
  border-radius: 2.5rem;
  background: rgba(238, 244, 247, 0.9);
  box-shadow: 0.5rem 0.5rem 2rem rgba(199, 209, 214, 0.8),
    -0.5rem -0.5rem 2rem rgba(189, 255, 255, 0.8);
}

.c-card:not(.c-card--clear) .c-box {
  background: #ebf2f5;
  box-shadow: 0.5rem 0.5rem 1.5rem #c3c9cb, -0.5rem -0.5rem 1.5rem #ffffff;
}

.c-box--bleed,
.c-box--bleed-y,
.c-box--bleed-b {
  margin-bottom: -var(--container-padx);
}

.c-box--bleed,
.c-box--bleed-y,
.c-box--bleed-t {
  margin-top: -var(--container-padx);
}

.c-box--bleed,
.c-box--bleed-x,
.c-box--bleed-l {
  margin-left: -var(--container-padx);
}

.c-box--bleed,
.c-box--bleed-x,
.c-box--bleed-r {
  margin-right: -var(--container-padx);
}

.c-box a:not([class]):hover {
  /* background-color: var(--c-grey-6); */
}

.c-box--glass {
  /* background: rgba(255, 255, 255, 0.4); */
}

.c-box--clear {
  background: transparent;
}

.c-box--round {
  /* border-radius: 0.25em; */
  /* background: linear-gradient(145deg, #b9c3c4, #dbe8e9); */
}

.c-box--shadow {
  /* margin-bottom: 1.5em;
  box-shadow: var(--shadow); */
}

.c-box--square {
  border-radius: 0;
}

.c-box__heading {
  padding-top: 0;
  border-bottom: 1px solid currentColor;
  margin-top: 0;
  font-family: var(--serifFancy);
}

.c-box__heading--center {
  border: 0;
  text-align: center;
}

.c-box .l-grid:not(.l-grid--bleed) {
  margin-right: -1rem;
  margin-left: -1rem;
}

@media (--bp-tp) {
  .c-box .l-grid:not(.l-grid--bleed) {
    margin-right: -var(--container-padx);
    margin-left: -var(--container-padx);
  }
}

/* .c-card .c-box {
  padding: var(--ws-md);
}

@media only screen and (--bp-tp) {
  .c-card .c-box {
    padding: var(--ws-lg);
  }
} */
