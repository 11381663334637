.c-card {
  position: relative;
  display: flex;
  /* overflow: auto; */
  /* min-height: 20em; */
  flex-flow: column;
  justify-content: center;
  padding: 1em;
  background: rgba(255, 255, 255, 0.4);
  color: #000;

  & h1:first-child,
  & h2:first-child,
  & h3:first-child,
  & h4:first-child {
    padding-top: 0;
    margin-top: 0;
  }
}

.c-card__body {
  padding: 2em 0;
}

.c-card--dark {
  background: var(--brand-primary);
  color: #fff;

  & a {
    color: #fff;
    &:hover,
    &:focus {
      color: var(--brand-primary-light);
    }
  }
}

.c-card--glass {
  background-color: rgba(255, 255, 255, 0.6);
}

.c-card--clear {
  background-color: transparent;
}

.u-shadow {
  margin-bottom: 1.5em;
  box-shadow: 0 1em 1em 0 rgba(0, 0, 0, 0.1);
}

.c-card--diagonal-left,
.c-card--diagonal {
  margin-top: 1vw;
  margin-bottom: 1vw;
  overflow: hidden;
}

.c-card--triangle-left {
  margin-top: 1vw;
  margin-bottom: 1vw;
  overflow: hidden;
}

.c-card--triangle-right-top,
.c-card--triangle-left-top {
  margin-top: 1vw;
  overflow: hidden;
}

.c-card--triangle-right-bottom,
.c-card--triangle-left-bottom {
  margin-bottom: 1vw;
  overflow: hidden;
}

.c-card--triangle-right {
  margin-top: 1vw;
  margin-bottom: 1vw;
  overflow: hidden;
}

.c-card--diagonal-right,
.c-card--diagonal:nth-child(even) {
  margin-top: 1vw;
  margin-bottom: 1vw;
  overflow: hidden;
}

@supports (clip-path: polygon(0 3vw, 100% 0, 100% calc(100% - 3vw), 0 100%)) {
  .c-card--diagonal-left,
  .c-card--diagonal {
    margin-top: 0;
    margin-bottom: 0;
    padding: calc(1rem + 3vw) 0;
    /* background: var(--c-pink); */
    clip-path: polygon(0 3vw, 100% 0, 100% calc(100% - 3vw), 0 100%);
    z-index: 2;
  }

  .c-card--triangle-left {
    margin-top: 0;
    margin-bottom: 0;
    padding: calc(1rem + 3vw) 0;
    clip-path: polygon(0 3vw, 100% 0, 100% 100%, 0 calc(100% - 3vw));
    z-index: 2;
  }

  .c-card--triangle-left-top {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc(1rem + 3vw);
    clip-path: polygon(0 3vw, 100% 0, 100% 100%, 0 100%);
    z-index: 2;
  }

  .c-card--triangle-left-bottom {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc(1rem + 3vw);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 3vw));
    z-index: 2;
  }

  .c-card--triangle-right {
    z-index: 2;
    padding: calc(1rem + 3vw) 0;
    margin-top: 0;
    margin-bottom: 0;
    clip-path: polygon(0 0, 100% 3vw, 100% calc(100% - 3vw), 0 100%);
    /* overflow: auto; */
  }

  .c-card--triangle-right-top {
    z-index: 2;
    padding: calc(1rem + 3vw) 0;
    margin-top: 0;
    margin-bottom: 0;
    clip-path: polygon(0 0, 100% 3vw, 100% 100%, 0 100%);
    /* overflow: auto; */
  }

  .c-card--triangle-right-bottom {
    z-index: 2;
    padding: calc(1rem + 3vw) 0;
    margin-top: 0;
    margin-bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3vw), 0 100%);
    /* overflow: auto; */
  }

  .c-card--diagonal-right,
  .c-card--diagonal:nth-child(even) {
    margin-top: 0;
    margin-bottom: 0;
    padding: calc(1rem + 3vw) 0;
    clip-path: polygon(0 0, 100% 3vw, 100% 100%, 0 calc(100% - 3vw));
    /* overflow: auto; */
    z-index: 1;
  }
}

.c-card--flush {
  margin-top: -3vw;
}

.u-bg-gradient--pink {
  background: linear-gradient(20deg, var(--c-pink-5) 10%, var(--c-pink-6)) !important;
  color: var(--c-pink-4-b);
}

.u-bg-gradient--teal {
  background: linear-gradient(300deg, var(--c-teal-4) 10%, var(--c-teal-6)) !important;
  color: var(--c-teal-5-b);
}

/* .u-bg-gradient--pink {
  background: linear-gradient(20deg, var(--c-pink-5) 10%, var(--c-pink-6)) !important;
  color: var(--c-pink-4-b);
}

.u-bg-gradient--teal {
  background: linear-gradient(300deg, var(--c-teal-5) 10%, var(--c-teal-6)) !important;
  color: var(--c-teal-5-b);
}

.u-bg-gradient--pink-teal {
  background: linear-gradient(320deg, var(--c-teal-7) 10%, var(--c-pink-7)) !important;
  color: var(--c-teal-6-b);
}

.u-bg-gradient--pink-green {
  background: linear-gradient(160deg, #00868160 10%, #00868100), linear-gradient(20deg, var(--c-green-5) 10%, var(--c-green-6)) !important;
  color: var(--c-teal-5-b);
}

.u-bg-gradient--green {
  background: linear-gradient(20deg, var(--c-green-4) 10%, var(--c-green-6)) !important;
  color: var(--c-green-4-b);
}

.u-bg-gradient--purple {
  background: linear-gradient(300deg, var(--c-purple-4) 10%, var(--c-purple-6)) !important;
  color: var(--c-purple-4-b);
} */

.c-card--light {
  background: var(--brand-primary-light);
}

.c-card--angled {
  overflow: hidden;
  padding-top: 140px;
  padding-bottom: 140px;
  clip-path: polygon(0 calc(0% + 4vw), 100% 0, 100% calc(100% - 4vw), 0 100%);
  overflow: auto;
}

.c-card__section {
  max-width: 35em;
  padding: 20px 0;
  margin: 0 auto;
}

.c-card__section--fluid {
  max-width: none;
}

.c-card__body {
}

.c-card__heading {
  margin: 1em 0;
}

.c-card__heading--main {
  position: relative;
  margin-bottom: 0.5em;
  text-align: center;
}
