.u-serif {
  font-family: var(--serif) !important;
}

.u-sans-serif {
  font-family: var(--sansSerif) !important;
}

.u-text--brand {
  font-family: var(--serifFancy) !important;
}

.u-text--caps {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

h1.u-text--brand,
.h1.u-text--brand {
  font-size: var(--fs-brand-xx);
  line-height: var(--lh-brand-xx);
  font-weight: 500;
}

h2.u-text--brand,
.h2.u-text--brand {
  font-size: var(--fs-brand-xl);
  line-height: var(--lh-brand-xl);
  font-weight: 500;
}

h3.u-text--brand,
.h3.u-text--brand {
  font-size: var(--fs-brand-lg);
  line-height: var(--lh-brand-lg);
  font-weight: 500;
}

h4.u-text--brand,
.h4.u-text--brand,
.u-text--brand {
  font-size: var(--fs-brand-md);
  line-height: var(--lh-brand-md);
}

h5.u-text--brand,
.h5.u-text--brand {
  font-size: var(--fs-brand-sm);
  line-height: var(--lh-brand-sm);
}

h6.u-text--brand,
.h6.u-text--brand {
  font-size: var(--fs-brand-xs);
  line-height: var(--lh-brand-xs);
}

.u-text--center {
  text-align: center !important;
}

.u-text--left {
  text-align: left !important;
}

.u-text--right {
  text-align: right !important;
}

.u-no-wrap {
  white-space: nowrap !important;
}

.u-mw--read {
  max-width: 50ch !important;
}

.u-mw--100 {
  max-width: 100%;
}

.u-fw--5 {
  font-weight: 500 !important;
}

.u-fw--6,
.u-fw--7 {
  font-weight: var(--sansFWBold) !important;
}

.u-fw--4 {
  font-weight: 400 !important;
}

.u-fw--3 {
  font-weight: 300 !important;
}

.u-pt--0,
.u-p--0,
.u-py--0 {
  padding-top: 0 !important;
}

.u-pt--xs,
.u-p--xs,
.u-py--xs {
  padding-top: var(--ws-xs) !important;
}

.u-pt--sm,
.u-p--sm,
.u-py--sm {
  padding-top: var(--ws-sm) !important;
}

.u-pt--md,
.u-p--md,
.u-py--md {
  padding-top: var(--ws-md) !important;
}

.u-pt--lg,
.u-p--lg,
.u-py--lg {
  padding-top: var(--ws-lg) !important;
}

.u-pt--xl,
.u-p--xl,
.u-py--xl {
  padding-top: var(--ws-xl) !important;
}

.u-pt--xx,
.u-p--xx,
.u-py--xx {
  padding-top: var(--ws-xx) !important;
}

.u-pb--0,
.u-p--0,
.u-py--0 {
  padding-bottom: 0 !important;
}

.u-pb--xs,
.u-p--xs,
.u-py--xs {
  padding-bottom: var(--ws-xs) !important;
}

.u-pb--sm,
.u-p--sm,
.u-py--sm {
  padding-bottom: var(--ws-sm) !important;
}

.u-pb--md,
.u-p--md,
.u-py--md {
  padding-bottom: var(--ws-md) !important;
}

.u-pb--lg,
.u-p--lg,
.u-py--lg {
  padding-bottom: var(--ws-lg) !important;
}

.u-pb--xl,
.u-p--xl,
.u-py--xl {
  padding-bottom: var(--ws-xl) !important;
}

.u-pb--xx,
.u-p--xx,
.u-py--xx {
  padding-bottom: var(--ws-xx) !important;
}

.u-pl--0,
.u-p--0,
.u-px--0 {
  padding-left: 0 !important;
}

.u-pl--xs,
.u-p--xs,
.u-px--xs {
  padding-left: var(--ws-xs) !important;
}

.u-pl--sm,
.u-p--sm,
.u-px--sm {
  padding-left: var(--ws-sm) !important;
}

.u-pl--md,
.u-p--md,
.u-px--md {
  padding-left: var(--ws-md) !important;
}

.u-pl--lg,
.u-p--lg,
.u-px--lg {
  padding-left: var(--ws-lg) !important;
}

.u-pl--xl,
.u-p--xl,
.u-px--xl {
  padding-left: var(--ws-xl) !important;
}

.u-pl--xx,
.u-p--xx,
.u-px--xx {
  padding-left: var(--ws-xx) !important;
}

.u-pr--0,
.u-p--0,
.u-px--0 {
  padding-right: 0 !important;
}

.u-pr--xs,
.u-p--xs,
.u-px--xs {
  padding-right: var(--ws-xs) !important;
}

.u-pr--sm,
.u-p--sm,
.u-px--sm {
  padding-right: var(--ws-sm) !important;
}

.u-pr--md,
.u-p--md,
.u-px--md {
  padding-right: var(--ws-md) !important;
}

.u-pr--lg,
.u-p--lg,
.u-px--lg {
  padding-right: var(--ws-lg) !important;
}

.u-pr--xl,
.u-p--xl,
.u-px--xl {
  padding-right: var(--ws-xl) !important;
}

.u-pr--xx,
.u-p--xx,
.u-px--xx {
  padding-right: var(--ws-xx) !important;
}

.u-mt--xs,
.u-m--xs,
.u-my--xs {
  margin-top: var(--ws-xs) !important;
}

.u-mt--sm,
.u-m--sm,
.u-my--sm {
  margin-top: var(--ws-sm) !important;
}

.u-mt--md,
.u-m--md,
.u-my--md {
  margin-top: var(--ws-md) !important;
}

.u-mt--lg,
.u-m--lg,
.u-my--lg {
  margin-top: var(--ws-lg) !important;
}

.u-mt--xl,
.u-m--xl,
.u-my--xl {
  margin-top: var(--ws-xl) !important;
}

.u-mt--xx,
.u-m--xx,
.u-my--xx {
  margin-top: var(--ws-xx) !important;
}

.u-mb--xs,
.u-m--xs,
.u-my--xs {
  margin-bottom: var(--ws-xs) !important;
}

.u-mb--sm,
.u-m--sm,
.u-my--sm {
  margin-bottom: var(--ws-sm) !important;
}

.u-mb--md,
.u-m--md,
.u-my--md {
  margin-bottom: var(--ws-md) !important;
}

.u-mb--lg,
.u-m--lg,
.u-my--lg {
  margin-bottom: var(--ws-lg) !important;
}

.u-mb--xl,
.u-m--xl,
.u-my--xl {
  margin-bottom: var(--ws-xl) !important;
}

.u-mb--xx,
.u-m--xx,
.u-my--xx {
  margin-bottom: var(--ws-xx) !important;
}

.u-ml--xs,
.u-m--xs,
.u-mx--xs {
  margin-left: var(--ws-xs) !important;
}

.u-ml--sm,
.u-m--sm,
.u-mx--sm {
  margin-left: var(--ws-sm) !important;
}

.u-ml--md,
.u-m--md,
.u-mx--md {
  margin-left: var(--ws-md) !important;
}

.u-ml--lg,
.u-m--lg,
.u-mx--lg {
  margin-left: var(--ws-lg) !important;
}

.u-ml--xl,
.u-m--xl,
.u-mx--xl {
  margin-left: var(--ws-xl) !important;
}

.u-ml--xx,
.u-m--xx,
.u-mx--xx {
  margin-left: var(--ws-xx) !important;
}

.u-mr--xs,
.u-m--xs,
.u-mx--xs {
  margin-right: var(--ws-xs) !important;
}

.u-mr--sm,
.u-m--sm,
.u-mx--sm {
  margin-right: var(--ws-sm) !important;
}

.u-mr--md,
.u-m--md,
.u-mx--md {
  margin-right: var(--ws-md) !important;
}

.u-mr--lg,
.u-m--lg,
.u-mx--lg {
  margin-right: var(--ws-lg) !important;
}

.u-mr--xl,
.u-m--xl,
.u-mx--xl {
  margin-right: var(--ws-xl) !important;
}

.u-mr--xx,
.u-m--xx,
.u-mx--xx {
  margin-right: var(--ws-xx) !important;
}

.u-mt--0,
.u-m--0,
.u-my--0 {
  margin-top: 0 !important;
}

.u-mb--0,
.u-m--0,
.u-my--0 {
  margin-bottom: 0 !important;
}

.u-ml--0,
.u-m--0,
.u-mx--0 {
  margin-left: 0 !important;
}

.u-mr--0,
.u-m--0,
.u-mx--0 {
  margin-right: 0 !important;
}

.u-mx--auto,
.u-mr--auto {
  margin-right: auto;
}

.u-mx--auto,
.u-ml--auto {
  margin-left: auto;
}

.u-d--none {
  display: none !important;
}

@media (--bp-ph) {
  .u-hidden--ph {
    display: none !important;
  }
}

@media (--bp-tp) {
  .u-hidden--tp {
    display: none !important;
  }
}

@media (--bp-tp) {
  .u-hidden--tl {
    display: none !important;
  }
}

@media (--bp-d) {
  .u-hidden--d {
    display: none !important;
  }
}

@media (--bp-dw) {
  .u-hidden--dw {
    display: none !important;
  }
}

@media (--bp-tpmax) {
  .u-hidden--tpmax {
    display: none !important;
  }
}

@media (--bp-tlmax) {
  .u-hidden--tlmax {
    display: none !important;
  }
}

@media (--bp-dmax) {
  .u-hidden--dmax {
    display: none !important;
  }
}

@media only screen and (hover: none) {
  .u-hidden--no-hover {
    display: none !important;
  }
}

@media only screen and (hover: hover) {
  .u-hidden--hover {
    display: none !important;
  }
}

.u-paper--vert {
  padding: 1.5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), /* The second layer */ 0 0.5rem 0 -0.25rem #eee,
    /* The second layer shadow */ 0 0.5rem 1px -0.23rem rgba(0, 0, 0, 0.15),
    /* The third layer */ 0 1rem 0 -0.5rem #eee,
    /* The third layer shadow */ 0 1rem 1px -0.6rem rgba(0, 0, 0, 0.15);
}

.u-paper--right {
  position: relative;
  padding: 1rem;
}

.u-paper--right,
.u-paper--right::before,
.u-paper--right::after {
  /* Add shadow to distinguish sheets from one another */
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.15);
}

.u-paper--right::before,
.u-paper--right::after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eee;
  content: "";
}

/* Second sheet of u-paper--right */
.u-paper--right::before {
  z-index: -1;
  top: 5px;
  left: 7px;
}

/* Third sheet of u-paper--right */
.u-paper--right::after {
  z-index: -2;
  top: 10px;
  left: 12px;
}

.u-paper--shuffled {
  z-index: 1000;
  position: relative;
  padding: 1rem;
  background: #fff;
}

.u-paper--shuffled,
.u-paper--shuffled::before,
.u-paper--shuffled::after {
  /* Styles to distinguish sheets from one another */
  border: 1px solid #bbb;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.u-paper--shuffled::before,
.u-paper--shuffled::after {
  position: absolute;
  width: 99%;
  height: 95%;
  background-color: #eee;
  content: "";
}

.u-paper--shuffled::before {
  z-index: -1;
  top: 0;
  right: 15px;
  transform: rotate(-1deg);
}

.u-paper--shuffled::after {
  z-index: -2;
  top: 5px;
  right: -5px;
  transform: rotate(1deg);
}

.u-fs--xs {
  font-size: var(--fs-xs);
}

.u-fs--sm {
  font-size: var(--fs-sm);
}

.u-fs--md {
  font-size: var(--fs-md);
}

.u-fs--lg {
  font-size: var(--fs-lg);
}

.u-fs--xl {
  font-size: var(--fs-xl);
}

.u-fs--xx {
  font-size: var(--fs-xx);
}

.u-lh-xs {
  line-height: var(--lh-xs);
}

.u-lh-sm {
  line-height: var(--lh-sm);
}

.u-lh-md {
  line-height: var(--lh-md);
}

.u-lh-lg {
  line-height: var(--lh-lg);
}

.u-lh-xl {
  line-height: var(--lh-xl);
}

.u-lh-xx {
  line-height: var(--lh-xx);
}

.u-text-xs {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
}

.u-text-sm {
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.u-text-md {
  font-size: var(--fs-md);
  line-height: var(--lh-md);
}

.u-text-lg {
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
}

.u-text-xl {
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
}

.u-text-xx {
  font-size: var(--fs-xx);
  line-height: var(--lh-xx);
}

.u-bg-white,
.u-bg--white {
  background: #fff !important;
}

.u-bg--grey-1 {
  background-color: var(--c-grey-1) !important;
  color: var(--c-grey-1-b) !important;
}

.u-bg--grey-2 {
  background-color: var(--c-grey-2) !important;
  color: var(--c-grey-2-b) !important;
}

.u-bg--grey-3 {
  background-color: var(--c-grey-3) !important;
  color: var(--c-grey-3-b) !important;
}

.u-bg--grey-4 {
  background-color: var(--c-grey-4) !important;
  color: var(--c-grey-4-b) !important;
}

.u-bg--grey-5 {
  background-color: var(--c-grey-5) !important;
  color: var(--c-grey-5-b) !important;
}

.u-bg--grey-6 {
  background-color: var(--c-grey-6) !important;
  color: var(--c-grey-6-b) !important;
}

.u-bg--grey-7 {
  background-color: var(--c-grey-7) !important;
  color: var(--c-grey-7-b) !important;
}

.u-bg--teal-1 {
  background-color: var(--c-teal-1) !important;
  color: var(--c-teal-1-b) !important;
}

.u-bg--teal-2 {
  background-color: var(--c-teal-2) !important;
  color: var(--c-teal-2-b) !important;
}

.u-bg--teal-3 {
  background-color: var(--c-teal-3) !important;
  color: var(--c-teal-3-b) !important;
}

.u-bg--teal-4 {
  background-color: var(--c-teal-4) !important;
  color: var(--c-teal-4-b) !important;
}

.u-bg--teal-5 {
  background-color: var(--c-teal-5) !important;
  color: var(--c-teal-5-b) !important;
}

.u-bg--teal-6 {
  background-color: var(--c-teal-6) !important;
  color: var(--c-teal-6-b) !important;
}

.u-bg--teal-7 {
  background-color: var(--c-teal-7) !important;
  color: var(--c-teal-7-b) !important;
}

.u-bg--blue-1 {
  background-color: var(--c-blue-1) !important;
  color: var(--c-blue-1-b) !important;
}

.u-bg--blue-2 {
  background-color: var(--c-blue-2) !important;
  color: var(--c-blue-2-b) !important;
}

.u-bg--blue-3 {
  background-color: var(--c-blue-3) !important;
  color: var(--c-blue-3-b) !important;
}

.u-bg--blue-4 {
  background-color: var(--c-blue-4) !important;
  color: var(--c-blue-4-b) !important;
}

.u-bg--blue-5 {
  background-color: var(--c-blue-5) !important;
  color: var(--c-blue-5-b) !important;
}

.u-bg--blue-6 {
  background-color: var(--c-blue-6) !important;
  color: var(--c-blue-6-b) !important;
}

.u-bg--blue-7 {
  background-color: var(--c-blue-7) !important;
  color: var(--c-blue-7-b) !important;
}

.u-bg--green-1 {
  background-color: var(--c-green-1) !important;
  color: var(--c-green-1-b) !important;
}

.u-bg--green-2 {
  background-color: var(--c-green-2) !important;
  color: var(--c-green-2-b) !important;
}

.u-bg--green-3 {
  background-color: var(--c-green-3) !important;
  color: var(--c-green-3-b) !important;
}

.u-bg--green-4 {
  background-color: var(--c-green-4) !important;
  color: var(--c-green-4-b) !important;
}

.u-bg--green-5 {
  background-color: var(--c-green-5) !important;
  color: var(--c-green-5-b) !important;
}

.u-bg--green-6 {
  background-color: var(--c-green-6) !important;
  color: var(--c-green-6-b) !important;
}

.u-bg--green-7 {
  background-color: var(--c-green-7) !important;
  color: var(--c-green-7-b) !important;
}

.u-bg--pink-1 {
  background-color: var(--c-pink-1) !important;
  color: var(--c-pink-1-b) !important;
}

.u-bg--pink-2 {
  background-color: var(--c-pink-2) !important;
  color: var(--c-pink-2-b) !important;
}

.u-bg--pink-3 {
  background-color: var(--c-pink-3) !important;
  color: var(--c-pink-3-b) !important;
}

.u-bg--pink-4 {
  background-color: var(--c-pink-4) !important;
  color: var(--c-pink-4-b) !important;
}

.u-bg--pink-5 {
  background-color: var(--c-pink-5) !important;
  color: var(--c-pink-5-b) !important;
}

.u-bg--pink-6 {
  background-color: var(--c-pink-6) !important;
  color: var(--c-pink-6-b) !important;
}

.u-bg--pink-7 {
  background-color: var(--c-pink-7) !important;
  color: var(--c-pink-7-b) !important;
}

.u-bg--purple-1 {
  background-color: var(--c-purple-1) !important;
  color: var(--c-purple-1-b) !important;
}

.u-bg--purple-2 {
  background-color: var(--c-purple-2) !important;
  color: var(--c-purple-2-b) !important;
}

.u-bg--purple-3 {
  background-color: var(--c-purple-3) !important;
  color: var(--c-purple-3-b) !important;
}

.u-bg--purple-4 {
  background-color: var(--c-purple-4) !important;
  color: var(--c-purple-4-b) !important;
}

.u-bg--purple-5 {
  background-color: var(--c-purple-5) !important;
  color: var(--c-purple-5-b) !important;
}

.u-bg--purple-6 {
  background-color: var(--c-purple-6) !important;
  color: var(--c-purple-6-b) !important;
}

.u-bg--purple-7 {
  background-color: var(--c-purple-7) !important;
  color: var(--c-purple-7-b) !important;
}

@media (--bp-tp) {
  .u-cols--2,
  .u-cols--3 {
    break-inside: avoid;

    & > * {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid-column;
    }
  }

  .u-cols--2 {
    columns: 2;
  }

  .u-cols--3 {
    columns: 3;
  }
}

.u-flex--grow {
  flex-grow: 1;
}

.u-wb--all {
  word-break: break-all;
}

.u-d--inline-block {
  display: inline-block !important;
}

.u-d--block {
  display: block !important;
}

.u-d--flex {
  display: flex !important;
}

.u-d--inline-flex {
  display: inline-flex !important;
}

.u-jc--center {
  justify-content: center;
}

.u-ai--center {
  align-items: center;
}

.u-ar--169 {
  position: relative;
  height: 0;
  padding-top: 25px;
  padding-bottom: 56.25%; /* 16:9 */

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.u-ws--nowrap {
  white-space: nowrap;
}

.u-o--h,
.u-ox--h,
.u-oy--h {
  overflow: hidden;
}

.u-d--grid {
  display: grid;
}

.u-gap--xl {
  gap: var(--ws-xl);
}

.u-gap--lg {
  gap: var(--ws-lg);
}

.u-gap--md {
  gap: var(--ws-md);
}

.u-gap--sm {
  gap: var(--ws-sm);
}

.u-gap--xs {
  gap: var(--ws-xs);
}

.u-grid--2 {
  grid-template-columns: repeat(2, 1fr);
}

.u-grid--3 {
  grid-template-columns: repeat(3, 1fr);
}

.u-grid--4 {
  grid-template-columns: repeat(4, 1fr);
}
