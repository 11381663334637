.c-button {
  display: inline-block;
  padding: 0.5em;
  /* border: 2px solid var(--c-grey-3); */
  border: 0;
  margin: 1em 0;
  background: var(--c-grey-6);
  border-radius: 0.3em;
  /* box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 -0.25em 0 rgba(0, 0, 0, 0.25),
    0 0.25em 0.25em rgba(0, 0, 0, 0.05); */
  color: var(--c-grey-4-b);
  font-weight: 500;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.25);
  transition: background-color 0.15s ease-in, box-shadow 0.15s ease-in;
  vertical-align: middle;

  &:hover,
  &:focus {
    background-color: var(--c-grey-7);
    color: var(--c-grey-7-b);
  }

  &:active {
    /* box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 2px 0 hsla(0, 0%, 100%, 0.1),
      inset 0 0.25em 0.5em rgba(0, 0, 0, 0.05); */
  }
  &:disabled {
    opacity: 0.3;
    filter: grayscale();
    pointer-events: none;
  }
}

.c-button--media {
  background: transparent;
  border: 1px solid currentColor;
  color: currentColor;
  box-shadow: none;
  line-height: 1;
  min-width: 1em;
  margin: 0;
  padding: 0.5em;

  &:hover,
  &:focus {
    background-color: var(--c-grey-3);
    color: var(--c-grey-3-b);
  }
}

.c-button--teal {
  background: var(--c-teal-6);
  color: var(--c-teal-6-b);

  &:hover,
  &:focus {
    background-color: var(--c-teal-7);
    color: var(--c-teal-7-b);
  }
}

.c-button--blue {
  background: var(--c-blue-6);
  color: var(--c-blue-6-b);

  &:hover,
  &:focus {
    background-color: var(--c-blue-7);
    color: var(--c-blue-7-b);
  }
}

.c-button--green {
  background: var(--c-green-6);
  color: var(--c-green-6-b);

  &:hover,
  &:focus {
    background-color: var(--c-green-5);
    color: var(--c-green-5-b);
  }
}

.c-button--pink {
  background: var(--c-pink-6);
  color: var(--c-pink-6-b);

  &:hover,
  &:focus {
    background-color: var(--c-pink-5);
    color: var(--c-pink-5-b);
  }
}

.c-button--purple {
  background: var(--c-purple-6);
  color: var(--c-purple-6-b);

  &:hover,
  &:focus {
    background-color: var(--c-purple-7);
    color: var(--c-purple-7-b);
  }
}

.c-button--ghost:not(:hover) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  outline: 1px solid currentColor;
}

.c-button--large {
  padding: 1em;
}

.c-button--small {
  padding: 0.25em 0.5em;
  font-size: 0.8em;
}

.c-button--list {
  background: transparent;
  margin: 0;
  padding-left: 0;
  border-radius: 0;
  font-weight: 400;
}

li:nth-child(even) .c-button--list {
  border-top: 1px solid var(--c-grey-6);
}

.c-button--list:hover,
.c-button--list:focus,
li:nth-child(even) .c-button--list:hover,
li:nth-child(even) .c-button--list:focus {
  background: var(--c-blue-6);
  color: var(--c-blue-6-b);
}

.c-button--close {
  background: transparent;
  border: 0;
  color: currentColor;
  box-shadow: none;
  line-height: 1;
  min-width: 1em;
  margin: 0;
  padding: 0.5em;
}
