.l-container,
.l-container--fluid {
  width: 100%;
  max-width: calc(100% - 1rem);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* padding-right: 0.5rem;
  padding-left: 0.5rem; */
  margin-right: auto;
  margin-left: auto;
}

@media (--bp-tp) {
  .l-container,
  .l-container--fluid {
    padding-right: var(--container-padx);
    padding-left: var(--container-padx);
  }

  .l-container > .l-grid,
  .l-container--fluid > .l-grid {
    margin-right: -var(--container-padx);
    margin-left: -var(--container-padx);
  }
}

@media (--bp-d) {
  .l-container {
    max-width: 60rem;
  }
}

@media (--bp-d) {
  .l-container--wide {
    max-width: 70rem;
  }
}

.l-grid {
  position: relative;
  z-index: 1;
  display: block;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  list-style-type: none;
  word-spacing: -0.43em;
}

.l-grid::before,
.l-grid::after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  white-space: normal;
  word-spacing: normal;
}

.l-grid > *,
[class^="l-grid__cell"] {
  position: relative;
  display: inline-block;
  *display: inline;
  width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  vertical-align: top;
  white-space: normal;
  word-spacing: normal;
  zoom: 1;
}

[class*="l-grid__cell"] {
  padding: 1rem;
}

[class*="l-grid__cell"] img {
  max-width: 100%;
}

@media (--bp-tp) {
  [class*="l-grid__cell"] {
    padding: var(--container-padx);
  }
}

[class*="l-grid__cell"]::before,
[class*="l-grid__cell"]::after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  white-space: normal;
  word-spacing: normal;
}

[class*="l-grid__cell"] .l-grid {
  flex: 1 1 auto;
  margin: -var(--container-padx);
}

.l-grid__cell--12 {
  width: 100%;
  *width: 99.9%;
}

.l-grid__cell--11 {
  width: 91.66666667%;
  *width: 91.56666667%;
}

.l-grid__cell--10 {
  width: 83.33333333%;
  *width: 83.23333333%;
}

.l-grid__cell--9 {
  width: 75%;
  *width: 74.9%;
}

.l-grid__cell--8 {
  width: 66.66666667%;
  *width: 66.56666667%;
}

.l-grid__cell--7 {
  width: 58.33333333%;
  *width: 58.23333333%;
}

.l-grid__cell--6 {
  width: 50%;
  *width: 49.9%;
}

.l-grid__cell--5 {
  width: 41.66666667%;
  *width: 41.56666667%;
}

.l-grid__cell--4 {
  width: 33.33333333%;
  *width: 33.23333333%;
}

.l-grid__cell--3 {
  width: 25%;
  *width: 24.9%;
}

.l-grid__cell--2 {
  width: 16.66666667%;
  *width: 16.56666667%;
}

.l-grid__cell--1 {
  width: 8.33333333%;
  *width: 8.23333333%;
}

@media (--bp-ph) {
  .l-grid__cell--ph-12 {
    width: 100%;
    *width: 99.9%;
  }

  .l-grid__cell--ph-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .l-grid__cell--ph-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .l-grid__cell--ph-9 {
    width: 75%;
    *width: 74.9%;
  }

  .l-grid__cell--ph-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .l-grid__cell--ph-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .l-grid__cell--ph-6 {
    width: 50%;
    *width: 49.9%;
  }

  .l-grid__cell--ph-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .l-grid__cell--ph-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .l-grid__cell--ph-3 {
    width: 25%;
    *width: 24.9%;
  }

  .l-grid__cell--ph-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .l-grid__cell--ph-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (--bp-tp) {
  .l-grid__cell--tp-12 {
    width: 100%;
    *width: 99.9%;
  }

  .l-grid__cell--tp-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .l-grid__cell--tp-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .l-grid__cell--tp-9 {
    width: 75%;
    *width: 74.9%;
  }

  .l-grid__cell--tp-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .l-grid__cell--tp-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .l-grid__cell--tp-6 {
    width: 50%;
    *width: 49.9%;
  }

  .l-grid__cell--tp-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .l-grid__cell--tp-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .l-grid__cell--tp-3 {
    width: 25%;
    *width: 24.9%;
  }

  .l-grid__cell--tp-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .l-grid__cell--tp-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (--bp-tl) {
  .l-grid__cell--tl-12 {
    width: 100%;
    *width: 99.9%;
  }

  .l-grid__cell--tl-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .l-grid__cell--tl-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .l-grid__cell--tl-9 {
    width: 75%;
    *width: 74.9%;
  }

  .l-grid__cell--tl-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .l-grid__cell--tl-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .l-grid__cell--tl-6 {
    width: 50%;
    *width: 49.9%;
  }

  .l-grid__cell--tl-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .l-grid__cell--tl-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .l-grid__cell--tl-3 {
    width: 25%;
    *width: 24.9%;
  }

  .l-grid__cell--tl-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .l-grid__cell--tl-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (--bp-d) {
  .l-grid__cell--d-12 {
    width: 100%;
    *width: 99.9%;
  }

  .l-grid__cell--d-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .l-grid__cell--d-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .l-grid__cell--d-9 {
    width: 75%;
    *width: 74.9%;
  }

  .l-grid__cell--d-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .l-grid__cell--d-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .l-grid__cell--d-6 {
    width: 50%;
    *width: 49.9%;
  }

  .l-grid__cell--d-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .l-grid__cell--d-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .l-grid__cell--d-3 {
    width: 25%;
    *width: 24.9%;
  }

  .l-grid__cell--d-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .l-grid__cell--d-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (--bp-dw) {
  .l-grid__cell--dw-12 {
    width: 100%;
    *width: 99.9%;
  }

  .l-grid__cell--dw-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }

  .l-grid__cell--dw-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }

  .l-grid__cell--dw-9 {
    width: 75%;
    *width: 74.9%;
  }

  .l-grid__cell--dw-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }

  .l-grid__cell--dw-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }

  .l-grid__cell--dw-6 {
    width: 50%;
    *width: 49.9%;
  }

  .l-grid__cell--dw-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }

  .l-grid__cell--dw-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }

  .l-grid__cell--dw-3 {
    width: 25%;
    *width: 24.9%;
  }

  .l-grid__cell--dw-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }

  .l-grid__cell--dw-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

.l-grid__cell--auto {
  flex: 1 0 0px;
  width: auto;
}

@media (--bp-ph) {
  .l-grid__cell--ph-auto {
    flex: 1 0 0px;
    width: auto;
  }
}

@media (--bp-tp) {
  .l-grid__cell--tp-auto {
    flex: 1 0 0px;
    width: auto;
  }
}

@media (--bp-tl) {
  .l-grid__cell--tl-auto {
    flex: 1 0 0px;
    width: auto;
  }
}

@media (--bp-d) {
  .l-grid__cell--d-auto {
    flex: 1 0 0px;
    width: auto;
  }
}

@media (--bp-dw) {
  .l-grid__cell--dw-auto {
    flex: 1 0 0px;
    width: auto;
  }
}

.order-12 {
  order: 12;
}

.order-11 {
  order: 11;
}

.order-10 {
  order: 10;
}

.order-9 {
  order: 9;
}

.order-8 {
  order: 8;
}

.order-7 {
  order: 7;
}

.order-6 {
  order: 6;
}

.order-5 {
  order: 5;
}

.order-4 {
  order: 4;
}

.order-3 {
  order: 3;
}

.order-2 {
  order: 2;
}

.order-1 {
  order: 1;
}

.order-0 {
  order: 0;
}

@media only screen and (--bp-ph) {
  .order-12--ph {
    order: 12;
  }

  .order-11--ph {
    order: 11;
  }

  .order-10--ph {
    order: 10;
  }

  .order-9--ph {
    order: 9;
  }

  .order-8--ph {
    order: 8;
  }

  .order-7--ph {
    order: 7;
  }

  .order-6--ph {
    order: 6;
  }

  .order-5--ph {
    order: 5;
  }

  .order-4--ph {
    order: 4;
  }

  .order-3--ph {
    order: 3;
  }

  .order-2--ph {
    order: 2;
  }

  .order-1--ph {
    order: 1;
  }

  .order-0--ph {
    order: 0;
  }
}

@media only screen and (--bp-tp) {
  .order-12--tp {
    order: 12;
  }

  .order-11--tp {
    order: 11;
  }

  .order-10--tp {
    order: 10;
  }

  .order-9--tp {
    order: 9;
  }

  .order-8--tp {
    order: 8;
  }

  .order-7--tp {
    order: 7;
  }

  .order-6--tp {
    order: 6;
  }

  .order-5--tp {
    order: 5;
  }

  .order-4--tp {
    order: 4;
  }

  .order-3--tp {
    order: 3;
  }

  .order-2--tp {
    order: 2;
  }

  .order-1--tp {
    order: 1;
  }

  .order-0--tp {
    order: 0;
  }
}

@media only screen and (--bp-tl) {
  .order-12--tl {
    order: 12;
  }

  .order-11--tl {
    order: 11;
  }

  .order-10--tl {
    order: 10;
  }

  .order-9--tl {
    order: 9;
  }

  .order-8--tl {
    order: 8;
  }

  .order-7--tl {
    order: 7;
  }

  .order-6--tl {
    order: 6;
  }

  .order-5--tl {
    order: 5;
  }

  .order-4--tl {
    order: 4;
  }

  .order-3--tl {
    order: 3;
  }

  .order-2--tl {
    order: 2;
  }

  .order-1--tl {
    order: 1;
  }

  .order-0--tl {
    order: 0;
  }
}

@media only screen and (--bp-d) {
  .order-12--d {
    order: 12;
  }

  .order-11--d {
    order: 11;
  }

  .order-10--d {
    order: 10;
  }

  .order-9--d {
    order: 9;
  }

  .order-8--d {
    order: 8;
  }

  .order-7--d {
    order: 7;
  }

  .order-6--d {
    order: 6;
  }

  .order-5--d {
    order: 5;
  }

  .order-4--d {
    order: 4;
  }

  .order-3--d {
    order: 3;
  }

  .order-2--d {
    order: 2;
  }

  .order-1--d {
    order: 1;
  }

  .order-0--d {
    order: 0;
  }
}

@media only screen and (--bp-dw) {
  .order-12--dw {
    order: 12;
  }

  .order-11--dw {
    order: 11;
  }

  .order-10--dw {
    order: 10;
  }

  .order-9--dw {
    order: 9;
  }

  .order-8--dw {
    order: 8;
  }

  .order-7--dw {
    order: 7;
  }

  .order-6--dw {
    order: 6;
  }

  .order-5--dw {
    order: 5;
  }

  .order-4--dw {
    order: 4;
  }

  .order-3--dw {
    order: 3;
  }

  .order-2--dw {
    order: 2;
  }

  .order-1--dw {
    order: 1;
  }

  .order-0--dw {
    order: 0;
  }
}

.offset-11 {
  margin-left: 91.66666667%;
  *margin-left: 91.56666667%;
}

.offset-10 {
  margin-left: 83.33333333%;
  *margin-left: 83.23333333%;
}

.offset-9 {
  margin-left: 75%;
  *margin-left: 74.9%;
}

.offset-8 {
  margin-left: 66.66666667%;
  *margin-left: 66.56666667%;
}

.offset-7 {
  margin-left: 58.33333333%;
  *margin-left: 58.23333333%;
}

.offset-6 {
  margin-left: 50%;
  *margin-left: 49.9%;
}

.offset-5 {
  margin-left: 41.66666667%;
  *margin-left: 41.56666667%;
}

.offset-4 {
  margin-left: 33.33333333%;
  *margin-left: 33.23333333%;
}

.offset-3 {
  margin-left: 25%;
  *margin-left: 24.9%;
}

.offset-2 {
  margin-left: 16.66666667%;
  *margin-left: 16.56666667%;
}

.offset-1 {
  margin-left: 8.33333333%;
  *margin-left: 8.23333333%;
}

@media only screen and (--bp-tp) {
  .offset-11--tp {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }

  .offset-10--tp {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }

  .offset-9--tp {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-8--tp {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }

  .offset-7--tp {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }

  .offset-6--tp {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-5--tp {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }

  .offset-4--tp {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }

  .offset-3--tp {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-2--tp {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }

  .offset-1--tp {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }

  .offset-0--tp {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media only screen and (--bp-tl) {
  .offset-11--tl {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }

  .offset-10--tl {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }

  .offset-9--tl {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-8--tl {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }

  .offset-7--tl {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }

  .offset-6--tl {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-5--tl {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }

  .offset-4--tl {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }

  .offset-3--tl {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-2--tl {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }

  .offset-1--tl {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }

  .offset-0--tl {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media only screen and (--bp-d) {
  .offset-11--d {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }

  .offset-10--d {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }

  .offset-9--d {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-8--d {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }

  .offset-7--d {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }

  .offset-6--d {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-5--d {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }

  .offset-4--d {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }

  .offset-3--d {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-2--d {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }

  .offset-1--d {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }

  .offset-0--d {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media only screen and (--bp-dw) {
  .offset-11--dw {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }

  .offset-10--dw {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }

  .offset-9--dw {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-8--dw {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }

  .offset-7--dw {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }

  .offset-6--dw {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-5--dw {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }

  .offset-4--dw {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }

  .offset-3--dw {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-2--dw {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }

  .offset-1--dw {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }

  .offset-0--dw {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.no-wrap [class^="l-grid__cell"] {
  flex-shrink: 1;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.direction-row {
  flex-direction: row;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.direction-column {
  flex-direction: column;
}

.direction-column-reverse {
  flex-direction: column-reverse;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-end [class^="l-grid__cell"] {
  vertical-align: bottom;
}

.align-center {
  align-items: center;
}

.align-center [class^="l-grid__cell"] {
  vertical-align: middle;
}

.align-baseline {
  align-items: baseline;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-end [class^="l-grid__cell"] {
  vertical-align: bottom;
}

.align-content-center {
  align-content: center;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-space-around {
  align-content: space-around;
}

.align-self-stretch {
  align-self: stretch;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
  vertical-align: bottom;
}

.align-self-center {
  align-self: center;
  vertical-align: middle;
}

.align-self-baseline {
  align-self: baseline;
  vertical-align: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-start.l-grid {
  text-align: left;
}

.justify-end {
  justify-content: flex-end;
}

.justify-end.l-grid {
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
}

.justify-end.l-grid [class^="l-grid__cell"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-center {
  justify-content: center;
}

.justify-center.l-grid {
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}

.justify-center.l-grid [class^="l-grid__cell"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-between.l-grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.justify-space-between.l-grid [class^="l-grid__cell"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-around.l-grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.justify-space-around.l-grid [class^="l-grid__cell"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-space-evenly.l-grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.justify-space-evenly.l-grid [class^="l-grid__cell"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.l-grid--bleed [class*="l-grid__cell--"] {
  padding: 0;
}

.l-grid__cell--l-grid {
  display: flex;
  flex-direction: column;
}

.l-grid__cell--l-grid.direction-row {
  flex-direction: row;
}

.l-grid__cell--bleed {
  padding: 0;
}

.l-grid__cell--bleed-x {
  padding: var(--container-padx) 0;
}

.l-grid__cell--bleed-right {
  padding: var(--container-padx) 0 var(--container-padx) var(--container-padx);
}

.l-grid__cell--bleed-left {
  padding: var(--container-padx) var(--container-padx) var(--container-padx) 0;
}

.l-grid__cell--bleed-y {
  padding: 0 var(--container-padx);
}

.l-grid--auto > * {
  width: auto;
  flex-basis: auto;
  flex-grow: 1;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

@media (--bp-ph) {
  .l-grid__cell--ph-bleed {
    padding: 0;
  }

  .l-grid__cell--ph-bleed {
    padding: var(--container-padx) 0;
  }

  .l-grid__cell--ph-bleed-right {
    padding: var(--container-padx) 0 var(--container-padx) var(--container-padx);
  }

  .l-grid__cell--ph-bleed-left {
    padding: var(--container-padx) var(--container-padx) var(--container-padx) 0;
  }

  .l-grid__cell--ph-bleed-y {
    padding: 0 var(--container-padx);
  }
}

@media (--bp-tp) {
  .l-grid__cell--tp-bleed {
    padding: 0;
  }

  .l-grid__cell--tp-bleed-x {
    padding: var(--container-padx) 0;
  }

  .l-grid__cell--tp-bleed-right {
    padding: var(--container-padx) 0 var(--container-padx) var(--container-padx);
  }

  .l-grid__cell--tp-bleed-left {
    padding: var(--container-padx) var(--container-padx) 0 var(--container-padx);
  }

  .l-grid__cell--tp-bleed-y {
    padding: 0 var(--container-padx);
  }
}

@media (--bp-tl) {
  .l-grid__cell--tl-bleed {
    padding: 0;
  }

  .l-grid__cell--tl-bleed-x {
    padding: var(--container-padx) 0;
  }

  .l-grid__cell--tl-bleed-right {
    padding: var(--container-padx) 0 var(--container-padx) var(--container-padx);
  }

  .l-grid__cell--tl-bleed-left {
    padding: var(--container-padx) var(--container-padx) var(--container-padx) 0;
  }

  .l-grid__cell--tl-bleed-y {
    padding: 0 var(--container-padx);
  }
}

@media (--bp-d) {
  .l-grid__cell--d-bleed {
    padding: 0;
  }

  .l-grid__cell--d-bleed-x {
    padding: var(--container-padx) 0;
  }

  .l-grid__cell--d-bleed-right {
    padding: var(--container-padx) 0 var(--container-padx) var(--container-padx);
  }

  .l-grid__cell--d-bleed-left {
    padding: var(--container-padx) var(--container-padx) var(--container-padx) 0;
  }

  .l-grid__cell--d-bleed-y {
    padding: 0 var(--container-padx);
  }
}

@media (--bp-dw) {
  .l-grid__cell--dw-bleed {
    padding: 0;
  }

  .l-grid__cell--dw-bleed-x {
    padding: var(--container-padx) 0;
  }

  .l-grid__cell--dw-bleed-right {
    padding: var(--container-padx) 0 var(--container-padx) var(--container-padx);
  }

  .l-grid__cell--dw-bleed-left {
    padding: var(--container-padx) var(--container-padx) 0 var(--container-padx);
  }

  .l-grid__cell--dw-bleed-y {
    padding: 0 var(--container-padx);
  }
}

.flex-img {
  display: block;
  width: 100%;
  *width: auto;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
}

.flex-footer {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}

.flex-footer > :last-child {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .hidden-xxs {
    display: none;
  }
}

@media (--bp-ph) {
  .hidden--ph {
    display: none;
  }
}

@media (--bp-tp) and (--bp-tpmax) {
  .hidden--tp {
    display: none;
  }
}

@media (--bp-tl) and (--bp-tlmax) {
  .hidden--tl {
    display: none;
  }
}

@media (--bp-d) and (--bp-dmax) {
  .hidden--d {
    display: none;
  }
}

@media (--bp-dw) {
  .hidden--dw {
    display: none;
  }
}
