.t-cms h1,
.t-cms .h1 {
  font-size: var(--fs-brand-xx);
  line-height: var(--lh-brand-xx);
  font-weight: 500;
}

.t-cms h2,
.t-cms .h2 {
  font-size: var(--fs-brand-xl);
  line-height: var(--lh-brand-xl);
  font-weight: 500;
}

.t-cms h3,
.t-cms .h3 {
  font-size: var(--fs-brand-lg);
  line-height: var(--lh-brand-lg);
  font-weight: 500;
}

.t-cms h4,
.t-cms .h4 {
  font-size: var(--fs-brand-md);
  line-height: var(--lh-brand-md);
}

.t-cms h5,
.t-cms .h5 {
  font-size: var(--fs-brand-sm);
  line-height: var(--lh-brand-sm);
}

.t-cms h6,
.t-cms .h6 {
  font-size: var(--fs-brand-xs);
  line-height: var(--lh-brand-xs);
}

.t-cms .c-list--dash li p {
  display: inline-block;
  vertical-align: top;
}

.t-cms ul {
  padding: 0;
  list-style: none;
  text-align: left;

  & li {
    padding-left: 1em;
    margin: 0.5em 0;
  }

  & li::before {
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    content: "\2013";
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }
}

.t-content--center {
  text-align: center;
}

.t-content--left {
  text-align: left;
}

.t-content--right {
  text-align: right;
}

.t-headers--center h2,
.t-headers--center h3,
.t-headers--center h4,
.t-headers--center h5 {
  text-align: center;
}

.t-headers--left h2,
.t-headers--left h3,
.t-headers--left h4,
.t-headers--left h5 {
  text-align: left;
}

.t-headers--right h2,
.t-headers--right h3,
.t-headers--right h4,
.t-headers--right h5 {
  text-align: right;
}

.t-cms blockquote {
  margin-left: 2em;
}
