@import "variables.css";
@import "utilities.css";
@import "components/auction.css";
@import "components/box.css";
@import "components/button.css";
@import "components/card.css";
@import "components/list.css";
@import "components/form.css";
@import "components/nav.css";
@import "components/schedule.css";
@import "components/table.css";
@import "components/toc.css";
@import "components/touchdown.css";
@import "components/work.css";
@import "components/breadcrumb.css";
@import "components/dialog.css";
@import "layouts/container.css";
@import "layouts/grid.css";
@import "layouts/donut.css";
@import "themes/blog.css";
@import "themes/cms.css";
@import "themes/dashboard.css";
@import "themes/policy.css";
@import "themes/livestream.css";
@import "../fonts/Jost.css";

/*! sanitize.css v6.0.0 | CC0 License | github.com/csstools/sanitize.css */

/* Document
 * ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
  box-sizing: border-box; /* 2 */
  background-repeat: no-repeat; /* 1 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 4. Breaks words to prevent prevent overflow in all browsers (opinionated).
 */

html {
  line-height: 1.15; /* 1 */

  /* cursor: default; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
  word-break: break-word; /* 4 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  min-height: 100vh;
  margin: 0;
  /* display: flex;
  flex-flow: column nowrap; */
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

/* Grouping content
 * ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  overflow: visible; /* 2 */
  height: 0; /* 1 */
  box-sizing: content-box; /* 1 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */

::-moz-selection {
  background-color: #b3d4fc; /* 1 */
  color: #000; /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000; /* 1 */
  text-shadow: none;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */

/**
 * Collapse border spacing in all browsers (opinionated).
 */

table {
  border-collapse: collapse;
  word-break: normal;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Inherit styling in all browsers (opinionated).
 */

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Restore the focus styles unset by the previous rule in Firefox.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the inner border and padding in Firefox.
 */

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  box-sizing: border-box; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */

textarea {
  overflow: auto; /* 1 */
  resize: vertical; /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge, IE, and Safari.
 */

dialog {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  padding: 1em;
  border: solid;
  margin: auto;
  background-color: white;
  color: black;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/*
 * 1. Remove the tapping delay on clickable elements in all browsers (opinionated).
 * 2. Remove the tapping delay in IE 10.
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation; /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */

.u-hide,
[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled],
[disabled] {
  cursor: disabled;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
.u-sr,
[aria-hidden="false"][hidden]:not(:focus) {
  position: absolute;
  left: -10000px;
  display: inherit;
  clip: rect(0, 0, 0, 0);
}

html,
body {
  /* height: 100%; */
  /* background: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAUFBQUFBQYGBgYICQgJCAwLCgoLDBINDg0ODRIbERQRERQRGxgdGBYYHRgrIh4eIisyKigqMjw2NjxMSExkZIYBDQ4OEBAQFxgYFx8iHiIfLisnJysuRjI2MjYyRmtDTkNDTkNrXnJdV11yXqqFdnaFqsSknKTE7dTU7f/////////CABEIAJQA6wMBIQACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQII/9oACAEBAAAAAOyYAI3gqAIaCFSiUSpm1NZtlArIBKzNrm0lJSFiFuVTUUKRnRAqWLFJUoBKSoCpQlQoGaxtKpYQWCkJprCVqALBKEWTQFlRWaBDWSrJRLYI1EpFlS3Kis0CFECrFgCFFiFhbmalErOrlULJqWFRUazYsLEaIi2CoLFECUWFM6EoJUrNl1mallI0kCWKpKk1cqJqTUlZqmVp/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIQAAAAgAlJYVKBBZRLCykpCxQEKhZQAIoCKCKIqKAJQAASgEoJQSpQCUBKCUBKEFCCiFligEUJRBSWWUJR/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQMQAAAAoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB/8QAIxAAAgMAAgEFAQEBAAAAAAAAAAEQESExQWECEiBRgXETMv/aAAgBAQABPwB/yODy0W+Ui7M+Gn4JGUWdRZvIm6ORsxq0K616f9IXkuxtnK0/C4ZcZOFjZtxzqh5WFm9lzsb2i6fAzGsZvgr6KZpi0bzS0YzCt+Fyq0aPckcq0O+j9Ftl1rqi8L9RvY7hW/tIo4L8mHJRhjMi11OHAn3Q0uxM/BFqMRS9SZSP5NeJ5h4oQ/MpoxF+C7OHG2KmsMGbWQxULPlUYjYoc1Y+YT+KUMxI6GzS4tFlNxsXRcrH8cOI05N6LcXF2NuKTeospNnv9X+ns9jqr93X8OIssqK8xSKMjL5N+Db6QtP6Ys+XEXLvoY/5FHI+MnPgtEOatCnzNr9E0yz+D8lxZhcYIboxn9Zg0ZFwlQkOvi15jPsowbc2XtUUWuB0nh+w2u2UrtlQ3HJZQobio4E/r5WujI91dM4ZyclKPcuIw5i7eGC+TLsuG2ZfBsXpRvRpaLSMjDDIxDZyJVa904OXRkt0Whswbosv6LHgnbKb7K24oecFmlllrtlqXXYmkPRzkYYuC41ahPT3eGJNXtzhajeppUUhpPGOotF47MeM4LXEbrZhhaKRhY3XRpsYin94Ut0XpcMrNKoRyJUN1GZg1DPyKqKMN+o46i/sz4UjxZltXpahKOzgxHWn6PCvo29M+4qi0Y3pfpvjgTTw7dKMG0e30/UaWUtPyKhWhDi5Z+nI0ciMjB6ZwZwKhtLkv0nqZ6dGkrqFtz3RVMvRcFJiSeFCHjhpFJFKxIoqGkmN0rOhz//EABQRAQAAAAAAAAAAAAAAAAAAAID/2gAIAQIBAT8ADv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAgP/aAAgBAwEBPwAO/wD/2Q==') repeat; */
  font-display: swap;
  font-family: var(--sansSerif);
  font-weight: var(--sansFW);
  line-height: 1.63em;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html,
  body {
    scroll-behavior: auto;
  }
}

@media screen {
  html,
  body {
    font-range: 480px 1600px; /* viewport widths between which font-size is fluid */
    font-size: responsive 16px 18px; /* min-size, max-size */
  }

  body {
    /* background: var(--bgColor); */
    /* background: linear-gradient(
      200deg,
      #bcd6e0 10%,
      #b7e5e3,
      #b6e6c1,
      #b7e5e3,
      #f0accd,
      #e3b9d8 90%
    ) !important; */
    /* acaaff  */
    background: linear-gradient(
      200deg,
      #acaaff 150px,
      #a2efeb,
      #d4f5ff,
      hsl(177, 47%, 81%),
      hsl(296, 60%, 82%),
      #bbbdff 90%
    ) !important;
    /* background: linear-gradient(
      200deg,
      #bcd6e0 10%,
      #b7e5e3,
      #b6e6c1,
      #b7e5e3,
      #f0accd,
      #e3b9d8 90%
    );
    background-attachment: fixed !important; */
  }
}

@supports not (old: ie) {
  body {
    background-attachment: scroll !important;
  }
}

strong,
b {
  font-weight: stronger;
}

/* Block elements
 ========================================================================== */

/*
 * Margins
 */

p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 1.19em;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  padding-top: 0.438em;
}

dl:not([class]) {
  margin-left: 1em;

  & dd:not([class]) {
    margin-left: 2em;
  }
}

@media (--bp-tl) {
  dl:not([class]) {
    columns: 2;

    & > div,
    & dt,
    & dd {
      break-inside: avoid;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
    }
  }
}

/* Headings
 ========================================================================== */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  margin: 0 0 1rem;
  font-weight: 300;
}

h5,
.h5,
h6,
.h6 {
  margin: 0 0 1rem;
  font-weight: 500;
}

/* Add margin if adjacent element */
* + h1,
* + .h1 {
  padding-top: 0.188em;
  margin-bottom: 0.354em;
}

* + h2,
* + .h2 {
  padding-top: 0.571em;
  margin-bottom: 0.357em;
}

* + h3,
* + .h3 {
  padding-top: 0.217em;
  margin-bottom: 0.913em;
}

* + h4,
* + .h4 {
  padding-top: 0.316em;
  margin-bottom: 1.05em;
}

* + h5,
* + .h5,
* + h6,
* + .h6 {
  padding-top: 0.438em;
  margin-bottom: 1.19em;
}

/*
 * Sizes
 */

h1,
.h1 {
  font-size: var(--fs-xx);
  line-height: var(--lh-xx);
}

h2,
.h2 {
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
}

h3,
.h3 {
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
}

h4,
.h4 {
  font-size: var(--fs-md);
  line-height: var(--lh-md);
}

h5,
.h5 {
  font-size: var(--fs-md);
  line-height: var(--lh-md);
}

h6,
.h6 {
  font-size: var(--fs-md);
  line-height: 1.63em;
}

/* Lists
 ========================================================================== */

ul,
ol {
  padding-left: 1rem;
}

/*
 * Reset margin for nested lists
 */

ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */

/*
 * 1. Add the correct box sizing and height in Firefox.
 * 2. Show the overflow in Edge and IE.
 * 3. Add the correct text-align in Edge and IE.
 * 4. Style
 */

hr,
.hr {
  /* 1 */
  height: 0;
  box-sizing: content-box;
  /* 2 */
  overflow: visible;
  /* 3 */
  text-align: inherit;
  /* 4 */
  margin: 0 0 0.5rem 0;
  border: 0;
  border-top: 1px solid var(--c-grey-1);
}

/* Add margin if adjacent element */
* + hr,
* + .hr {
  margin-top: 0.5rem;
}

/* Address
 ========================================================================== */

address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */

blockquote {
  margin: 0 0 0.5rem;
  /* font-size: @base-blockquote-font-size; */
  /* line-height: @base-blockquote-line-height; */
  /* font-style: @base-blockquote-font-style; */
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 0.5rem;
}

/*
 * Content
 */

blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 0.5rem;
  /* font-size: @base-blockquote-footer-font-size; */
  /* line-height: @base-blockquote-footer-line-height; */
}

/* Preformatted text
 ========================================================================== */

/*
 * 1. Contain overflow in all browsers.
 */

pre {
  /* font: @base-pre-font-size e("/") @base-pre-line-height var(--monotype); */
  /* color: @base-pre-color; */
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
}

pre code {
  font-family: var(--monotype);
}

/* Selection pseudo-element
 ========================================================================== */

::-moz-selection {
  /* background: @base-selection-background; */
  /* color: @base-selection-color; */
  /* text-shadow: none; */
}

::selection {
  /* background: @base-selection-background; */
  /* color: @base-selection-color; */
  /* text-shadow: none; */
}

/* HTML5 elements
 ========================================================================== */

/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */

details, /* 1 */
main {
  /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* Iframe
 ========================================================================== */

iframe {
  border: 0;
}

/* Prevent the 300ms delay for touchscreen interactions
 ========================================================================== */

/*
 * Most browsers prevent the 300ms delay automatically for sites that use the `width=device-width` property.
 * For Safari on iOS 9.3+, IE 11 and Edge on desktops and IE 11 on Windows Phone 8.1 it must be applied manually.
 */

a,
area,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

.u-link,
a:not([class]) {
  padding: var(--ws-xs) 0;
  border-bottom: 1px solid currentColor;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s ease-in;
}

.u-link:hover,
.u-link:focus,
a:not([class]):hover,
a:not([class]):focus {
  padding: var(--ws-xs);
  margin: 0 -var(--ws-xs);
  /* background-color: rgba(255, 255, 255, 0.4); */
  background-color: var(--c-teal-7);
  border-bottom-color: transparent;
  color: var(--c-teal-7-b);
  text-transform: none;
}

.wagtail-userbar a:not([class]):hover {
  padding: 0.8em;
}

.u-link--shy {
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    border-bottom: 1px solid var(--c-grey-1);
    background-color: transparent;
  }
}

.u-link--small {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1;
}

.aside:last-of-type {
  flex: 1;
}

.c-social-icons__link {
  display: inline-block;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 3px;
  line-height: 0;
  text-decoration: none;
}

.c-social-icons__link + .c-social-icons__link {
  margin-left: 0.5em;
}

.c-social-icons__icon {
  width: 1.2em;
  max-height: 1.2em;
  padding: 5px;
  background: var(--c-teal-2);
  border-radius: 3px;
}

.c-social-icons__link:hover .c-social-icons__icon--dreamwidth {
  background: #fff;
}

.c-social-icons__link:hover .c-social-icons__icon--dreamwidth g {
  fill: #b22226 !important;
}

.c-social-icons__link:hover .c-social-icons__icon--tumblr {
  background: #35465c;
}

.c-social-icons__link:hover .c-social-icons__icon--twitter {
  background: #1da1f2;
}

.c-social-icons__icon--tumblr path,
.c-social-icons__icon--twitter path {
  fill: #fff;
}

.c-logo {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1.25em;
  margin-right: 0.1em;
  background: url(/static/img/img-e19cc5.svg);
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: text-bottom;
}

.c-logo--link {
  position: relative;
  display: inline-block;
  padding: 0.25em;
  color: #000;
  font-family: var(--serifFancy);
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    color: #555;
  }
}

.c-readable {
  max-width: 60em;
}

.c-copyright {
  font-size: responsive 14px 16px; /* min-size, max-size */

  ul {
    padding: 0;
    margin: 0;
  }
}

@media (--bp-tp) {
  .c-copyright {
    & ul {
      display: inline-flex;
      flex-flow: row wrap;
      padding: 0;
      margin: 0 -1em;
      list-style: none;
    }

    & li {
      display: block;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding: 0;
      margin: 0.5em;
    }
  }
}

@media print {
  .c-copyright a {
    display: none;
  }
}

.c-navbar {
  margin: 0 0 0.5em;
}

.c-navbar + .c-navbar {
  margin: -0.5em 0 0.5em;
}

.c-nav {
  display: block;
  padding: 0;
  margin: 0;
  font-size: 28px;
  list-style: none;
}

.c-nav--admin {
  .c-nav__item {
    line-height: 1;
  }
}

.c-nav__item {
  display: flex;
  align-self: center;
  justify-content: center;
}

.c-nav--right {
  display: flex;
  flex-flow: row wrap;
  margin-left: auto;
  justify-content: space-evenly;
}

.c-nav--center {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
}

.c-nav__link {
  padding: 0.2em;
  border-bottom: 2px solid transparent;
  margin-right: 0.8em;
  margin-left: 0.8em;
  color: #000;
  font-family: var(--sansSerif);
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  transition: border-bottom-color 0.2s ease-in;

  &:hover,
  &:focus {
    border-bottom-color: currentColor;
  }
}

.c-nav__button {
  padding: 0.2rem;
  margin-right: 0.8em;
  margin-left: 0.8em;
  /* font-family: var(--serifFancy); */
  border: 2px solid var(--c-teal-3);
  background-color: transparent;
  color: inherit;
  font-size: 16px;
  /* font-weight: 400; */
  line-height: 1;
  text-decoration: none;
  transition: border-color 0.2s, background-color 0.2s;

  &:hover,
  &:focus {
    border: 2px solid var(--c-teal-4);
    background-color: var(--c-teal-4);
    color: var(--c-teal-4-b);
  }
}

.c-hero {
  padding: 3em 0;

  img,
  svg {
    display: block;
  }
}

.c-hero__subtitle {
  font-family: var(--sansSerif);
  font-size: var(--fs-xx);
}

.c-tagline {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  margin: 0;
  font-family: var(--sansSerif);
  font-size: 1.5em;
  /* font-weight: 300; */
  line-height: 1;
  justify-content: space-between;
  justify-content: space-evenly;
  list-style: none;
}

.c-tagline__tag {
  padding: 0 0.25em;
  font-size: 1.2rem;
}

.c-flag-list {
  display: flex;
  flex-flow: row wrap;
  /* justify-content: space-evenly; */
  padding: 0;
  margin: 0.5em 0;
  list-style: none;
}

.c-flag-list__item {
  flex: 1 0 auto;
  border-top: 5px solid transparent;
  padding: 0.5em;
  font-size: var(--fs-md);
  text-align: center;
  white-space: nowrap;
}

@media screen and (min-width: 480px) {
  .c-flag-list__item--fic {
    border-color: var(--c-teal);
  }

  .c-flag-list__item--vid {
    border-color: var(--c-blue);
  }

  .c-flag-list__item--cosplay {
    border-color: var(--c-green);
  }

  .c-flag-list__item--podfic {
    border-color: var(--c-pink);
  }

  .c-flag-list__item--art {
    border-color: var(--c-purple);
  }
}

.c-con-ad {
  margin: 1em auto;
  display: inline-flex;
  flex-flow: column nowrap;
}

.c-con-ad__image {
  display: block;
}

.c-con-ad__stripes {
  margin: 0.5em 0 1em;
  min-height: 5px;
  background: linear-gradient(
    to right,
    var(--c-teal) 0%,
    var(--c-teal) 20%,
    var(--c-blue) 20%,
    var(--c-blue) 40%,
    var(--c-green) 40%,
    var(--c-green) 60%,
    var(--c-pink) 60%,
    var(--c-pink) 80%,
    var(--c-purple) 80%,
    var(--c-purple) 100%
  );
}

.c-con-ad__title {
  font-family: var(--brandFont);
  font-size: 3.7rem;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
}

.c-staff {
  display: flex;
  flex-flow: column nowrap;
}

.c-staff__member {
  position: relative;
  background: #fff;
  padding: 0 var(--container-padx) var(--container-padx) var(--container-padx);
  border-radius: 0.5em;
  overflow: hidden;
  margin: var(--container-padx) 0;
  box-shadow: var(--shadow);

  &:nth-child(even) {
    align-self: flex-end;
  }
}

.c-staff__member-nym {
  /* display: inline-block; */
  background: var(--c-teal);
  color: var(--c-teal-b);
  margin: 0 -var(--container-padx);
  padding: 0.5em var(--container-padx);
}

.c-staff__member:nth-child(5n + 1) .c-staff__member-nym {
  background-color: var(--c-teal);
  color: var(--c-teal-b);
}

.c-staff__member:nth-child(5n + 2) .c-staff__member-nym {
  background-color: var(--c-blue);
  color: var(--c-blue-b);
}

.c-staff__member:nth-child(5n + 3) .c-staff__member-nym {
  background-color: var(--c-green);
  color: var(--c-green-b);
}

.c-staff__member:nth-child(5n + 4) .c-staff__member-nym {
  background-color: var(--c-pink);
  color: var(--c-pink-b);
}

.c-staff__member:nth-child(5n + 5) .c-staff__member-nym {
  background-color: var(--c-purple);
  color: var(--c-purple-b);
}

.c-staff__member-title,
.c-staff__member-nym {
  /* margin-top: 0; */
  /* padding-top: 0; */
}

.c-staff__member-nym {
  /* font-family: var(--serifFancy); */
  font-size: var(--fs-lg);
}

.c-staff__member-title {
  margin: 1rem 0;
  font-size: var(--fs-xs);
  text-transform: uppercase;
}

.c-donate {
  color: black;
}

.c-donate__title {
  font-family: var(--serifFancy);
  font-size: 2rem;
}

.c-donate__arc {
  /* overflow: hidden; */
  max-width: 200px;
  margin: 0 auto 2em;
}

.c-order {
  width: 100%;
  word-break: normal;

  & td,
  & th {
    padding: 0.5rem;
  }

  & caption {
    text-align: left;
  }
}

.c-order--rowcolor tr:nth-child(even) td {
  background-color: #eaeaea;
}

.c-order__headcell {
  border-bottom: 1px solid var(--c-grey-2);
}

.c-order__totalrow {
  border-top: 1px solid var(--c-grey-2);
}

.c-order__total,
.c-order__itemcell {
  text-align: center;
}

.c-order__headcell--left,
.c-order__itemcell--left {
  text-align: left;
}

.c-order__total {
  font-weight: var(--sansFWBold);
}

.c-order__totalheader {
  text-align: left;
}

@media (--bp-ph) {
  .c-order,
  .c-order tr,
  .c-order td {
    display: block;
    text-align: left;
  }

  .c-order thead,
  .c-order th {
    display: none;
  }

  .c-order [data-header]::before {
    content: attr(data-header) ": ";
    font-weight: var(--sansFWBold);
  }
}

.c-polaroid-link {
  &:focus {
    outline: none;
  }

  &:hover .c-polaroid,
  &:focus .c-polaroid {
    transform: scale(1.1);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.25);
  }

  &:focus .c-polaroid {
    outline: 2px dotted var(--c-teal);
  }

  &:hover .c-polaroid--left,
  &:focus .c-polaroid--left {
    transform: scale(1.05) rotate(-2deg);
  }

  &:hover .c-polaroid--right,
  &:focus .c-polaroid--right {
    transform: scale(1.05) rotate(2deg);
  }
}

.c-polaroid {
  border: 0.5em solid white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

@media (--bp-tl) {
  .c-polaroid--left {
    transform: rotate(-2deg);
  }

  .c-polaroid--right {
    transform: rotate(2deg);
  }
}

.c-progress {
  position: relative;
  width: 100%;
  height: 0.4rem;
  background: linear-gradient(90deg, var(--c-pink), var(--c-blue), var(--c-green)) no-repeat;
}

.c-progress__bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--c-grey-6);
}

/* .stripe-button-el,
.stripe-button-el span {
  background: var(--c-teal-6) !important;
  color: var(--c-teal-6-b) !important;
  font-weight: 400 !important;

  &;hover,
  &:focus {

  }
} */

.c-vidstream {
  /* display: flex; */
  /* flex-flow: column; */
  /* max-width: calc(100% - 30em); */
  width: 100%;
  height: 100%;
}

@media (--bp-d) {
  .c-vidstream {
    flex-flow: row wrap;
    padding: 1rem 1rem 0;
  }
}

.c-vidstream__main {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  transition: width 0.5s ease-in-out;
}
@media (--bp-d) {
  .c-vidstream__main {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 2rem 1rem 1rem;
    width: 70%;

    &.is-closed {
      width: 100%;
    }
  }
}

.c-vidstream__main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto min-content;
  grid-template-areas: "c-vidstream__header" "c-vidstream__video" "c-vidcontrols";
  min-height: 100vh;
}

.c-vidstream__header {
  grid-area: c-vidstream__header;
}

.c-vidstream__video {
  grid-area: c-vidstream__video;
}

.c-vidcontrols {
  grid-area: c-vidcontrols;
}

@media (--bp-d) and (-ms-high-contrast: none) {
  .c-vidstream__main {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 3fr 5fr 3fr;
  }

  .c-vidstream__header {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .c-vidstream__video {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .c-vidcontrols {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
}

.c-vidstream__aside {
  position: static;
  width: 100%;
  transition: right 0.5s ease-in-out;
}

@media (--bp-d) {
  .c-vidstream__aside {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: auto;
    display: flex;
    align-items: flex-start;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    width: 30%;

    &.is-closed {
      right: -30%;
      overflow: hidden;
    }
  }
}

.c-vidstream__title {
  text-align: center;
  font-family: var(--serifFancy);
  font-range: 320px 1600px; /* viewport widths between which font-size is fluid */
  font-size: responsive 22px 42px; /* min-size, max-size */
  margin: 0;
  padding: 0;
}

.c-vidstream__playlist-toggle {
  display: none;
}

@media (--bp-d) {
  .c-vidstream__playlist-toggle {
    display: inline-block;
    font-size: 0.8rem;
  }
}

.c-vidstream__aside-toggles {
  margin-top: 1rem;
  /* min-width: 29em; */
  overflow: visible;
}

.c-vidstream__aside-toggle {
  margin-right: 1em;
  border: 0;
  border-bottom: 2px solid transparent;
  border-radius: 0;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    outline: none;
  }

  &.is-open {
    border-bottom-color: #fff;
  }
}

.c-vidstream__toggle-svg {
  display: inline-flex;
  align-items: center;

  & svg {
    margin-left: 0.25em;
  }

  &.is-closed {
    display: none;
  }
}

.c-vidstream__playlist,
.c-vidstream__schedule {
  width: 100%;
  overflow-y: auto;
  padding: 0 0 1rem;
  margin: 0;

  &.is-closed {
    display: none;
  }
}

.c-vidstream__schedule {
  padding-left: 1rem;
}

.c-vidstream__header {
  flex: 0 0 auto;
  padding-bottom: 0.5em;
  width: 100%;
  margin: 0 auto;
}

@media (--bp-tl) {
  .c-vidstream__back {
    position: absolute;
  }
}

.c-vidstream__video {
  /* flex: 1 1 auto; */
  /* width: 100%; */
  width: 100%;
  text-align: center;
  /* height: 0; */
  /* padding-bottom: 56.25%; */
  /* position: relative; */
}

.c-vidstream__iframe {
  /* width: auto; */
  height: 100%;
  max-width: 100%;
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* width: 100%; */
  /* height: 100%; */
}

.c-vidstream.windowed-fullscreen .c-vidstream__iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: black;
}

.c-vidstream.windowed-fullscreen .c-vidstream__aside,
.c-vidstream.windowed-fullscreen .c-vidcontrols {
  display: none;
}

.c-vidstream__loading {
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.c-vidstream__nowplaying {
  flex: 1 0 100%;
  width: 100%;
}

.c-video {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;

  & iframe {
    width: 100%;
    position: absolute;
    height: 100%;
  }
}

.c-vidfile {
  position: absolute;
  overflow: visible;
  width: 100%;
  opacity: 1;
  text-align: center;
  transform: translateY(0%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.is-current {
    position: relative;
  }

  &.is-prev {
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
  }

  &.is-next {
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);
  }
}

.c-vidfile__index {
  font-size: 0.8rem;
}

.c-vidfile__title,
.c-vidfile__author,
.c-vidfile__fandom,
.c-vidfile__song,
.c-vidfile__blurb,
.c-vidfile__artist {
  display: inline-block;
  margin: 0 0.25rem;
}

.c-vidfile__fandom,
.c-vidfile__song,
.c-vidfile__artist {
  display: inline;
  font-size: 0.8rem;
}

.c-vidfile__title,
.c-vidfile__author {
  font-family: var(--serifFancy);
  font-range: 320px 1600px; /* viewport widths between which font-size is fluid */
  font-size: responsive 24px 36px; /* min-size, max-size */
  line-height: 1.2;
}

.c-vidcontrols {
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  /* max-width: 69vmin; */
  min-height: 14rem;
  flex-flow: row nowrap;
  align-self: center;
  justify-content: center;
  padding-top: 0.25rem;
}

@media (--bp-d) {
  .c-vidcontrols {
    width: 100%;
  }
}

.c-vidcontrols__prev,
.c-vidcontrols__next {
  flex: 0 0 auto;
  margin-top: 2rem;
}

.c-vidcontrols__prev {
  padding-right: 1rem;
}

.c-vidcontrols__next {
  padding-left: 1rem;
}

.c-vidcontrols__vidfiles {
  position: relative;
  flex: 0 1 30rem;
}

@media (--bp-d) {
  .c-vidcontrols__vidfiles {
    flex-basis: 100%;
    max-width: 60em;
  }
}

.c-sidebarschedule__time {
  display: inline-block;
  width: 5rem;
}

.c-sidebarvids {
  margin: 0.5em 0;
  padding-left: 2em;
  counter-reset: vidlist;
  list-style: none;
}

.c-sidebarvids__item {
  position: relative;
  padding: 0.25em 0.5em;
  margin-left: 2em;
  counter-increment: vidlist;

  &::before {
    position: absolute;
    /* counter-increment: vidlist; */
    left: -2em;
    display: block;
    width: 2em;
    content: counter(vidlist);
    text-align: right;
  }
}

.c-sidebarvids__item.is-prev,
.c-sidebarvids__item.is-next {
  font-weight: 300;
}

.is-current .c-sidebarvids__link {
  background: rgba(255, 255, 255, 0.3);
  background-clip: border-box;
}

.c-sidebarvids__item.is-title {
  margin-left: -2rem;
  counter-increment: none;
  text-align: center;

  &::before {
    content: "";
  }
}

.c-sidebarvids__item.is-premiere {
  font-weight: 700;
}

.c-sidebarvids__link {
  display: block;
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.is-current .c-sidebarvids__link {
  &:hover,
  &:focus {
    background: transparent;
  }
}

.c-streamindex {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-streamindex__day {
  flex: 1 0 auto;
  width: 100%;
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}

@media (--bp-tl) {
  .c-streamindex__day {
    max-width: calc(100% / 2);
  }
}

@media (--bp-d) {
  .c-streamindex__day {
    max-width: calc(100% / 3);
  }
}

.c-streamindex__events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-streamindex__event {
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  font-weight: 300;
}

.c-streamindex__day-name {
  padding-left: 6rem;
  font-family: var(--serifFancy);
  font-size: var(--fs-xx);
}

.c-streamindex__event-time {
  display: inline-block;
  min-width: 5rem;
  text-align: right;
  margin-right: 1rem;
}

.c-streamindex__event-link,
.c-streamindex__event-title {
  /* padding: 0.25em; */
  font-size: var(--fs-md);
  color: currentColor;
  text-decoration: none;
  line-height: 1.5;
  padding: 0.5rem 0.2em;
  margin: 0 -0.2em;

  &:hover,
  &:focus {
    color: currentColor;
    text-decoration: none;
  }
}

.c-streamindex__event-vj {
  font-size: var(--fs-sm);
  line-height: 1;
}

.c-streamindex__event-link {
  display: block;
  font-weight: 400;
  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.2);
  }
}

.c-policy {
  height: 20em;
  padding: 1em;
  border: 1px solid currentColor;
  margin: 1em 0;
  overflow-y: auto;
  white-space: normal;
}

.c-policy li + li {
  margin-top: 1em;
}

.c-eventlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-eventlist__event {
  padding: 0.5em;
  margin: 0.5em 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.5em;
}

.c-eventlist__title {
  font-weight: 700;
}

.c-eventlist__info {
  font-size: var(--fs-xs);
}

.pagination {
  margin: 0.5em 0;
  font-size: var(--fs-xs);
}

figure[data-cut] {
  display: none;
}
