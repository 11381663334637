.t-policy {
  & dl:not([class]),
  & ul:not([class]) {
    margin-left: 1em;
    columns: 1 !important;
  }

  & dd:not([class]) {
    margin-left: 2em;
  }

  & ol {
    list-style-type: upper-roman;
  }

  & ol ol {
    padding-left: 3em;
    list-style-type: upper-alpha;
  }

  & ol ol ol {
    list-style-type: decimal;
  }
}
