.c-allynav {
  font-range: 480px 1600px; /* viewport widths between which font-size is fluid */
  font-size: responsive 14px 18px; /* min-size, max-size */

  & ul {
    list-style: none;
    padding: 0;
  }
}

.c-allynav__menu {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.c-allynav__menu--admin {
  background: rgba(255, 255, 255, 0.3);
  font-size: 14px;

  & .c-allynav__topitem {
    padding: 0.1em;
  }

  & .c-allynav__toplink {
    padding: 0.1em;
  }
}

.c-allynav__topbutton {
  position: relative;
  flex: 0 0 auto;
  text-align: center;

  &.has-submenu.open .c-allynav__submenu {
    box-shadow: var(--shadow-sm);
    display: flex;
  }
}

.c-allynav__topitem {
  position: relative;
  flex: 1 0 auto;
  text-align: center;

  &.has-submenu:hover,
  &.has-submenu.open {
    background: #fff;
    box-shadow: var(--shadow-sm);
  }

  /* &:hover .c-allynav__submenu, */
  &.has-submenu.open .c-allynav__submenu {
    box-shadow: var(--shadow-sm);
    display: flex;
  }
}

.c-allynav__topitem--right {
  margin-left: auto;
}

.c-allynav__topitem--brand {
  width: 100%;
}

@media (--bp-tp) {
  .c-allynav__topitem {
    flex: 0 0 auto;
    text-align: left;
  }

  .c-allynav__topitem--brand {
    width: auto;

    & + .c-allynav__topitem {
      margin-left: 0.25rem;
    }
  }
}

.c-allynav__topspan,
.c-allynav__sublink,
.c-allynav__toplink {
  display: block;
  padding: 0.5em 0.6em;
  border: 2px solid transparent;
  margin: 0;
  color: currentColor;
  text-decoration: none;
  word-break: keep-all;
  text-shadow: 0 1px 1px hsl(0deg 0% 100% / 25%);
}

.c-allynav__sublink,
.c-allynav__toplink {
  &:hover {
    background-color: #f2f2f2;
    color: #000;
  }

  &:focus {
    outline: none;
    border: 2px dotted var(--c-teal);
  }
}

.c-allynav__toplink--button {
  display: inline-block;
  border: 0;

  &:focus {
    border: 0;
  }
}

.c-allynav__divider {
  display: block;
  padding: 0.5rem 0.6rem 0;
  font-size: var(--fs-xxs);
  border: 2px solid transparent;
  border-top: 1px solid var(--c-grey-6);
  margin: 0;
  color: #000;
  text-decoration: none;
  word-break: keep-all;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.c-allynav__toplink.is-current::after {
  content: "";
  left: 0.8em;
  bottom: 0.5em;
  right: 0.8em;
  display: block;
  position: absolute;
  height: 1px;
  background: black;
}

.c-allynav__toplink[aria-haspopup="true"] {
  padding-right: 1.25em;

  &::after {
    position: static;
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
    margin-bottom: -0.35rem;
    content: "";
    line-height: 1;
    transform: rotate(135deg);
    transform-origin: top right;
  }
}

/* .c-allynav__topitem:hover .c-allynav__toplink[aria-haspopup="true"]::after, */
.c-allynav__topbutton[aria-haspopup="true"][aria-expanded="true"]::after,
.c-allynav__toplink[aria-haspopup="true"][aria-expanded="true"]::after {
  margin-bottom: 0;
  transform: rotate(315deg);
}

.c-allynav__submenu {
  position: absolute;
  z-index: var(--z-nav);
  display: none;
  min-width: 200px;
  flex-flow: column nowrap;
  background-color: #fff;
  text-align: left;
}

.c-allynav__submenu--end {
  right: 0;
}

.c-allynav__subitem {
  white-space: nowrap;
}

.c-allynav__subitem--multiline {
  white-space: normal;
  word-break: break-word;
}

.c-allynav__brand {
  position: relative;
  display: block;
  padding: 0.5rem 0 0.25rem;
  color: currentColor;
  font-size: 1.8rem;
  line-height: 1;
  font-family: var(--brandFont);
  text-decoration: none;

  &:hover,
  &:focus {
    background: linear-gradient(
        to right,
        #0c81a1 0%,
        #0c81a1 20%,
        #54c3bd 20%,
        #54c3bd 40%,
        #4eae66 40%,
        #4eae66 60%,
        #ee4087 60%,
        #ee4087 80%,
        #b1639d 80%,
        #b1639d 100%
      )
      no-repeat;
    background-position: bottom;
    background-size: 100% 3px;
  }
}

@media (--bp-tl) {
  .c-allynav__submenu {
    white-space: nowrap;
  }

  .c-allynav__brand {
    display: inline-block;
    margin-right: 0.75em;
  }

  .c-nav {
    display: flex;
    /* margin: 0 auto; */
    text-align: center;
  }
  .c-navbar {
    display: flex;
    flex-flow: row nowrap;
  }
}

@media print {
  .c-allynav {
    display: none !important;
  }
}
