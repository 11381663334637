.c-list {
  padding: 0 1.5em;

  & li {
    padding: 0 1em 0 0.5em;
    margin: 0.5em 0;
  }
}

.errorlist,
.c-list--unstyled {
  padding: 0;
  list-style: none;

  & li {
    padding: 0;
    margin: 0.5em 0;
  }
}

.c-list--nogaps li {
  margin: 0;
}

.c-list--inline {
  display: inline-flex;
  flex-flow: row wrap;
  padding: 0;
  margin: 0 -1em;
  list-style: none;

  & li {
    display: block;
    flex: 0 0 auto;
    padding: 0;
    margin: 0.5em;
  }
}

.c-list--dash {
  padding: 0;
  list-style: none;

  & li {
    padding-left: 1em;
    margin: 0.5em 0;
  }

  & li::before {
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    content: "\2013";
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }
}

.c-list--dot {
  list-style: disc;
}

.c-list--time {
  padding: 0;
  list-style: none;

  & li {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 1em;
    margin: 0.5em 0;
  }

  & li[data-time]::before {
    display: inline-block;
    flex: 0 0 auto;
    width: 5em;
    margin-right: 0.5em;
    margin-left: -1em;
    content: attr(data-time);
    text-align: right;
  }

  & .change {
    padding: 0;
    justify-content: center;
    font-size: 80%;
    text-transform: uppercase;
  }
}
