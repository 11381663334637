.l-donut {
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.l-donut__top {
  flex: none;
  min-height: 60px;
}

.l-donut__middle {
  display: flex;
  width: 100%;
  flex: 1 0 auto; /* 2 */
  flex-flow: column nowrap;

  &::after {
    content: '\00a0'; /* &nbsp; */
    display: block;
    margin-top: 1.5em;
    height: 0px;
    visibility: hidden;
  }
}

.l-donut__bottom {
  flex: none;
  margin: 0;
  padding: 1rem 0;
}
