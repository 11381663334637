.c-schedule {
  display: flex;
  flex-flow: row wrap;
  font-size: 12px;
  font-weight: 400;
  margin: 0 -var(--ws-md);
  word-break: normal;

  & input[type="checkbox"] + span::before {
    border-bottom-color: #000 !important;
    border-right-color: #000 !important;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .c-schedule {
    word-break: break-all;
  }
}

/* input[type="checkbox"]:checked ~ .c-schedule__subscribable {
  font-size: 0.75rem;
} */

.c-schedule__descriptions {
  width: 100%;
  padding: var(--ws-lg) 0;
  font-size: var(--fs-sm);
}

.c-schedule--show-fic,
.c-schedule--show-vid,
.c-schedule--show-vidshow,
.c-schedule--show-art,
.c-schedule--show-audio,
.c-schedule--show-break,
.c-schedule--show-community,
.c-schedule--show-general {
  & .c-schedule__event--fic,
  & .c-schedule__event--vid,
  & .c-schedule__event--vidshow,
  & .c-schedule__event--art,
  & .c-schedule__event--audio,
  & .c-schedule__event--community,
  & .c-schedule__event--general {
    background: transparent;
    color: transparent;
  }

  & .c-schedule__track--fic,
  & .c-schedule__track--vid,
  & .c-schedule__track--vidshow,
  & .c-schedule__track--art,
  & .c-schedule__track--audio,
  & .c-schedule__track--community,
  & .c-schedule__track--general {
    display: none;
  }
}

.c-schedule__key {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  padding: 0.5em;
  font-size: 16px;
}

.c-schedule__day {
  flex: 1 0 100%;
  width: 100%;
  margin: 1rem var(--ws-sm) 2rem var(--ws-sm);
}

.c-schedule__table-wrapper {
  min-width: 100%;
  max-width: none;
  overflow: auto hidden;
  -webkit-overflow-scrolling: touch !important;
}

@media (--bp-tl) {
  .c-schedule__table-wrapper {
    overflow: visible;
  }
}

.c-schedule__table {
  width: 100%;
  height: 100%;
  border: 1px solid var(--c-grey-2);
  background: #fff;
  background-clip: padding-box;
}

.c-schedule__time {
  width: 10%;
}

.c-schedule__roomheader {
  width: calc(100% / 7);
}

.c-schedule--2-rooms .c-schedule__roomheader {
  width: calc(100% / 3);
}

.c-schedule--3-rooms .c-schedule__roomheader {
  width: calc(100% / 4);
}

.c-schedule--4-rooms .c-schedule__roomheader {
  width: calc(100% / 5);
}

.c-schedule--5-rooms .c-schedule__roomheader {
  width: calc(100% / 6);
}

.c-schedule--6-rooms .c-schedule__roomheader {
  width: calc(100% / 7);
}

.c-schedule--7-rooms .c-schedule__roomheader {
  width: calc(100% / 8);
}

.c-schedule__dayheader {
  font-size: var(--fs-sm);
}

.c-schedule__time,
.c-schedule__dayheader,
.c-schedule__roomheader {
  white-space: nowrap;
}

@supports (-webkit-overflow-scrolling: touch) {
  .c-schedule__time,
  .c-schedule__dayheader,
  .c-schedule__roomheader {
    white-space: normal;
  }
}

.c-schedule__time,
.c-schedule__dayheader,
.c-schedule__roomheader,
.c-schedule__event {
  position: relative;
  min-height: 20px;
  padding: 0.25em;
  border: 1px solid var(--c-grey-2);
  background: var(--c-grey-7);
  color: var(--c-grey-7-b);
  text-align: center;
  vertical-align: middle;
  background-clip: padding-box;
}

.c-schedule--show-fic .c-schedule__track--fic {
  display: block;
}

.c-schedule__event--none {
  height: 1em;
  border: 0;
}

th[rowspan] ~ .c-schedule__event--none {
  border-top: 1px solid black;
}

.c-schedule--show-fic .c-schedule__event--fic,
.c-schedule__key--fic,
.c-schedule__event--fic {
  background-color: var(--c-writing);
  color: #000;
}

.c-schedule--show-vid .c-schedule__track--vid {
  display: block;
}

.c-schedule--show-vid .c-schedule__event--vid,
.c-schedule__key--vid,
.c-schedule__event--vid {
  background-color: var(--c-video);
  color: #000;
}

.c-schedule--show-vidshow .c-schedule__track--vidshow {
  display: block;
}

.c-schedule--show-vidshow .c-schedule__event--vidshow,
.c-schedule__key--vidshow,
.c-schedule__event--vidshow {
  background-color: var(--c-vidshow);
  color: #000;
}

.c-schedule--show-art .c-schedule__track--art {
  display: block;
}

.c-schedule--show-art .c-schedule__event--art,
.c-schedule__key--art,
.c-schedule__event--art {
  background-color: var(--c-art);
  color: #000;
}

.c-schedule--show-audio .c-schedule__track--audio {
  display: block;
}

.c-schedule--show-audio .c-schedule__event--audio,
.c-schedule__key--audio,
.c-schedule__event--audio {
  background-color: var(--c-audio);
  color: #000;
}

.c-schedule--show-community .c-schedule__track--community {
  display: block;
}

.c-schedule--show-community .c-schedule__event--community,
.c-schedule__key--community,
.c-schedule__event--community {
  background-color: var(--c-community);
  color: #000;
}

.c-schedule--show-closed .c-schedule__track--closed {
  display: block;
}

.c-schedule--show-closed .c-schedule__event--closed,
.c-schedule__key--closed,
.c-schedule__event--closed {
  background-color: var(--c-grey-6);
  color: var(--c-grey-6-b);
  color: #000;
}

.c-schedule--show-break .c-schedule__track--break {
  display: block;
}

.c-schedule--show-break .c-schedule__event--break,
.c-schedule__key--break,
.c-schedule__event--break {
  background-color: #fff;
  color: #000;
}

.c-schedule--show-general .c-schedule__track--general {
  display: block;
}

.c-schedule--show-general .c-schedule__event--general,
.c-schedule__key--general,
.c-schedule__event--general {
  background-color: var(--c-all);
  color: #000;
}

.c-schedule__event-popup {
  position: absolute;
  display: inherit;
  clip: rect(0, 0, 0, 0);
  text-align: left;
}

.c-schedule__event:hover,
.c-schedule__event:focus {
  z-index: 1;
}

.c-schedule__event:hover,
.c-schedule__event:focus {
  & .c-schedule__event-popup {
    z-index: var(--z-modal);
    left: 50%;
    display: block;
    width: auto;
    min-width: 300px;
    max-width: 70vw;
    height: auto;
    padding: 0.5em 0.75em;
    background: #fff;
    background-clip: #fff;
    border-radius: 0.5em;
    box-shadow: var(--shadow-sm);
    clip: auto;
    transform: translateX(-50%);
  }
}

@media (--bp-ph) {
  .c-schedule__day {
    flex: 1 1 auto;
  }

  .c-schedule__table {
    display: block;
    text-align: left;

    & thead,
    & tbody,
    & th,
    & tr {
      display: block;
      width: 100% !important;
      border: 0;
    }

    & td {
      display: grid;
      grid-template-columns: 6rem 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    & thead .c-schedule__time {
      display: none;
    }
  }

  .c-schedule__event-popup,
  .c-schedule__table td:first-child:last-child,
  .c-schedule__table thead tr:nth-child(2n),
  .c-schedule__event--none,
  .c-schedule__time,
  /* .c-schedule__event label, */
  /* .c-schedule__event .lever, */
  .c-schedule__event input {
    display: none !important;
  }

  .c-schedule__event label {
    padding-top: 0.5rem;
    font-size: 1.2em;
    grid-area: 1 / 2 / 2 / 5;
  }

  .c-schedule__event {
    display: none;
    flex-flow: row nowrap;
    align-items: center;
    text-align: left;
    text-align-last: left;
  }

  .c-schedule__event[data-description] {
    border: 0;
    /* display: flex; */
    /* flex-flow: row nowrap; */
    &:first-of-type {
      border-top: 1px solid black;
    }

    &::before {
      content: attr(data-track);
      width: 5.5em;
      flex: 0 0 auto;
      align-self: flex-start;
      padding-right: 0.5em;

      font-weight: 700;
      text-align: right;
      text-align-last: right;
      grid-area: 1 / 1 / 3 / 2;
    }

    &[data-time]::before {
      content: attr(data-time) " " attr(data-track);
      /* white-space: nowrap; */
    }
    &[data-time] ~ [data-time]::before {
      content: attr(data-track);
    }

    &::after {
      flex: 1 1 auto;
      content: attr(data-description);
      text-align: left;
      text-align-last: left;
      grid-area: 2 / 2 / 3 / 5;
    }
  }

  .c-schedule--show-fic,
  .c-schedule--show-vid,
  .c-schedule--show-vidshow,
  .c-schedule--show-art,
  .c-schedule--show-audio,
  .c-schedule--show-break,
  .c-schedule--show-community,
  .c-schedule--show-general {
    & .c-schedule__event--fic,
    & .c-schedule__event--vid,
    & .c-schedule__event--vidshow,
    & .c-schedule__event--art,
    & .c-schedule__event--audio,
    & .c-schedule__event--community,
    & .c-schedule__event--general {
      display: none;
    }

    & .c-schedule__track--fic,
    & .c-schedule__track--vid,
    & .c-schedule__track--vidshow,
    & .c-schedule__track--art,
    & .c-schedule__track--audio,
    & .c-schedule__track--community,
    & .c-schedule__track--general {
      display: none;
    }
  }

  .c-schedule--show-fic .c-schedule__event--fic,
  .c-schedule--show-vid .c-schedule__event--vid,
  .c-schedule--show-vidshow .c-schedule__event--vidshow,
  .c-schedule--show-art .c-schedule__event--art,
  .c-schedule--show-audio .c-schedule__event--audio,
  .c-schedule--show-community .c-schedule__event--community,
  .c-schedule--show-general .c-schedule__event--general {
    display: flex !important;
  }
}

.c-schedule__timegap td {
  border-top: 1px solid black;
  background-color: transparent;
  height: 1.5em;
}
