.c-worktable {
  width: 100%;
}

.c-worktable__header,
.c-worktable__cell {
  border-bottom: 1px solid currentColor;
  padding: 0.25em 0.5em;
  vertical-align: top;
}

.c-worktable__row:hover .c-worktable__cell {
  background-color: rgba(255, 255, 255, 0.5);
}

.c-work {
  padding: 0.5em;
  margin: 0.25em 0;
  background: white;
  box-shadow: 0 0.25em 0.25em 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  text-align-last: left;
  display: block;
  color: currentColor;
  text-decoration: none;
}

.c-work__title {
  font-size: var(--fs-md);
}

.c-work__cta {
  flex: 0 0 auto;
}

.c-work__meta {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row wrap;
  /* justify-content: space-between; */
  margin: 0 -0.5em;

  & > * {
    margin: 0.5em;
  }
}

.c-work__assets,
.c-work__submissions {
  font-size: var(--fs-xs);
  /* line-height: var(--lh-md); */
}

@media (--bp-tl) {
  .c-work__head,
  .c-work__footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .c-work__info {
    flex: 1 1 auto;
  }

  .c-work__cta {
    flex: 0 0 auto;
  }
}

.c-work__footer {
  margin-top: 0.5em;
}

.c-work__assets,
.c-work__submissions,
.c-work__fandom,
.c-work__song,
.c-work__artist,
.c-work__relationships,
.c-work__characters {
  display: flex;
  flex-flow: column nowrap;
}

.c-work__assets::before,
.c-work__submissions::before,
.c-work__fandom::before,
.c-work__song::before,
.c-work__artist::before,
.c-work__relationships::before,
.c-work__characters::before {
  font-size: var(--fs-xxs);
  line-height: var(--lh-xs);
  text-transform: uppercase;
}

.c-work__assets:not(:empty)::before {
  content: "File";
}

.c-work__submissions:not(:empty)::before {
  content: "Submissions";
}

.c-work__submissions[data-header]:not(:empty)::before {
  content: attr(data-header);
}

.c-work__submission-link {
  margin-top: var(--ws-sm);
  padding: var(--ws-xs) 0;
  border-bottom: 1px solid currentColor;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s ease-in;
  line-height: 1.5;
}

.c-work__submission-link:hover,
.c-work__submission-link:focus {
  padding: var(--ws-xs) 0;
  margin: 0 -var(--ws-xs);
  /* background-color: rgba(255, 255, 255, 0.4); */
  background-color: var(--c-teal-7);
  border-bottom-color: transparent;
  color: var(--c-teal-7-b);
  text-transform: none;
}

.c-work__fandom::before {
  content: "fandom";
}

.c-work__song::before {
  content: "song";
}

.c-work__artist::before {
  content: "artist";
}

.c-work__relationships::before {
  content: "relationships";
}

.c-work__characters::before {
  content: "characters";
}

.l-work-count {
  display: flex;
  flex-flow: row nowrap;
}
