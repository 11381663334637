.t-blogpost h2,
.t-blogpost .h2 {
  line-height: 1.5;
}

.t-blogpost h1 a,
.t-blogpost h2 a {
  border-bottom: 0;
  text-decoration: underline 1px;
  text-underline-offset: 2px;
  text-decoration-skip-ink: auto;
}
