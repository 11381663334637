.c-breadcrumb {
  list-style: none;
  margin: 0 0 2em;
  padding: 0;
  font-size: var(--fs-xs);
}

.c-breadcrumb li {
  display: inline;
}

.c-breadcrumb li:not(:last-child)::after {
  content: "›";
  margin: 0 0.25em;
  font-size: 1.5em;
}
