:root {
  --brandFont: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --serifFancy: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --serif: "Calibri", "Candara", "Segoe", "Segoe UI", "Optima", "Arial", sans-serif;
  --sansSerif: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --monotype: Consolas, monaco, monospace;
  --sansFW: 400;
  --sansFWBold: 600;
  --bgColor: #ccdee7;
  --brand-primary: #007e9e;
  --brand-secondary: #54c3bd;
  --brand-primary-dark: #00566b;
  --brand-primary-light: #e4f5ff;
  --brand-success: #4eae66;
  --brand-tertiary: #ee4087;
  --brand-quarternary: #b1639d;
  --actionColor: #00566b;
  --errorColor: #db512f;
  --logo: url("../img/magpie.svg");
  --linkColor: var(--brand-secondary);
  --linkColorHover: var(--brand-primary);

  /* Teals */
  --c-teal-1: #132229;
  --c-teal-2: #193f4e;
  --c-teal-3: #195f76;
  --c-teal-4: #0c81a1;
  --c-teal-5: #639fb8;
  --c-teal-6: #99becf;
  --c-teal-7: #ccdee7;
  --c-teal-1-b: #fff;
  --c-teal-2-b: #fff;
  --c-teal-3-b: #fff;
  --c-teal-4-b: #000;
  --c-teal-5-b: #000;
  --c-teal-6-b: #000;
  --c-teal-7-b: #000;
  --c-teal: var(--c-teal-4);
  --c-teal-b: var(--c-teal-4-b);

  /* Blues */
  --c-blue-1: #1c302e;
  --c-blue-2: #2f5d5a;
  --c-blue-3: #428f8a;
  --c-blue-4: #54c3bd;
  --c-blue-5: #87d2cd;
  --c-blue-6: #b1e2de;
  --c-blue-7: #d9f0ee;
  --c-blue-1-b: #fff;
  --c-blue-2-b: #fff;
  --c-blue-3-b: #fff;
  --c-blue-4-b: #000;
  --c-blue-5-b: #000;
  --c-blue-6-b: #000;
  --c-blue-7-b: #000;
  --c-blue: var(--c-blue-4);
  --c-blue-b: var(--c-blue-4-b);

  /* Greys */
  --c-grey-1: #252525;
  --c-grey-2: #525252;
  --c-grey-3: #737373;
  --c-grey-4: #969696;
  --c-grey-5: #bdbdbd;
  --c-grey-6: #d9d9d9;
  --c-grey-7: #f7f7f7;
  --c-grey-1-b: #fff;
  --c-grey-2-b: #fff;
  --c-grey-3-b: #fff;
  --c-grey-4-b: #000;
  --c-grey-5-b: #000;
  --c-grey-6-b: #000;
  --c-grey-7-b: #000;

  /* Purples */
  --c-purple-1: #2d1d28;
  --c-purple-2: #56334c;
  --c-purple-3: #824b73;
  --c-purple-4: #b1639d;
  --c-purple-5: #c689b5;
  --c-purple-6: #dab1cd;
  --c-purple-7: #edd7e6;
  --c-purple-1-b: #fff;
  --c-purple-2-b: #fff;
  --c-purple-3-b: #fff;
  --c-purple-4-b: #000;
  --c-purple-5-b: #000;
  --c-purple-6-b: #000;
  --c-purple-7-b: #000;
  --c-purple: var(--c-purple-4);
  --c-purple-b: var(--c-purple-4-b);

  /* Greens */
  --c-green-1: hsl(131, 25%, 14%);
  --c-green-2: hsl(134, 32%, 25%);
  --c-green-3: hsl(133, 35%, 37%);
  --c-green-4: hsl(135, 33%, 45%);
  --c-green-5: hsl(131, 36%, 63%);
  --c-green-6: hsl(129, 36%, 75%);
  --c-green-7: hsl(128, 35%, 88%);
  --c-green-1-b: #fff;
  --c-green-2-b: #fff;
  --c-green-3-b: #fff;
  --c-green-4-b: #000;
  --c-green-5-b: #000;
  --c-green-6-b: #000;
  --c-green-7-b: #000;
  --c-green: var(--c-green-4);
  --c-green-b: var(--c-green-4-b);

  /* Pinks */
  --c-pink-1: #391924;
  --c-pink-2: #712842;
  --c-pink-3: #ae3464;
  --c-pink-4: #ee4087;
  --c-pink-5: #f879a4;
  --c-pink-6: #fea8c1;
  --c-pink-7: #ffd5e0;
  --c-pink-1-b: #fff;
  --c-pink-2-b: #fff;
  --c-pink-3-b: #fff;
  --c-pink-4-b: #000;
  --c-pink-5-b: #000;
  --c-pink-6-b: #000;
  --c-pink-7-b: #000;
  --c-pink: var(--c-pink-4);
  --c-pink-b: var(--c-pink-4-b);

  /* Track Viz Colors */
  --c-all: #b3e2cd;
  --c-vidshow: #fdcdac;
  --c-video: #cbd5e8;
  --c-audio: #f4cae4;
  --c-art: #e6f5c9;
  --c-writing: #fff2ae;
  --c-community: #f1e2cc;
  --c-final: #ccc;

  /* Font-sizes */
  --fs-xxs: 0.6875rem;
  --fs-xs: 0.81rem;
  --fs-sm: 1rem;
  --fs-md: 1.19rem;
  --fs-lg: 1.44rem;
  --fs-xl: 1.75rem;
  --fs-xx: 2.06rem;

  /* Line-heights */
  --lh-xs: 1;
  --lh-sm: 1.5;
  --lh-md: 1.6;
  --lh-lg: 1.4;
  --lh-xl: 1.2;
  --lh-xx: 1.2;

  /* Brand font sizes */
  --fs-brand-xs: 1.62rem;
  --fs-brand-sm: 2rem;
  --fs-brand-md: 2.2rem;
  --fs-brand-lg: 2.5rem;
  --fs-brand-xl: 2.8rem;
  --fs-brand-xx: 3rem;

  /* Brand line heights */
  --lh-brand-xs: 1;
  --lh-brand-sm: 1.1;
  --lh-brand-md: 1.1;
  --lh-brand-lg: 1.1;
  --lh-brand-xl: 1.1;
  --lh-brand-xx: 1.1;

  /* Whitespace Sizes */
  --ws-xs: 0.125em;
  --ws-sm: 0.25em;
  --ws-md: 0.5em;
  --ws-lg: 1.5em;
  --ws-xl: 3em;
  --ws-xx: 5em;

  /* Breakpoints */
  --bp-xs: 320px;
  --bp-sm: 480px;
  --bp-md: 640px;
  --bp-lg: 760px;
  --bp-xl: 1020px;
  --bp-xx: 1200px;
  --bp-xs-max: 319px;
  --bp-sm-max: 479px;
  --bp-md-max: 639px;
  --bp-lg-max: 759px;
  --bp-xl-max: 1019px;
  --bp-xx-max: 1199px;

  --bp-p: 320px;
  --bp-t: 640px;
  --bp-l: 1020px;
  --bp-d: 1200px;

  --container-padx: 1.5rem;
  --shadow: 0 1em 1em 0.1em rgba(0, 0, 0, 0.15);
  --shadow-sm: 0 0.2em 0.2em 0.1em rgba(0, 0, 0, 0.15);

  --z-tooltip: 500;
  --z-modal: 900;
  --z-nav: 1000;
}

@custom-media --bp-ph (max-width: 40em);
@custom-media --bp-tp (min-width: 40em);
@custom-media --bp-tpmax (max-width: 46em);
@custom-media --bp-tl (min-width: 46em);
@custom-media --bp-tlmax (max-width: 60em);
@custom-media --bp-d (min-width: 60em);
@custom-media --bp-dmax (max-width: 80em);
@custom-media --bp-dw (min-width: 80em);
