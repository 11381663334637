.c-table {
  margin: 1em auto;
  width: 100%;

  & td,
  & th {
    text-align: left;
    padding: 0.25em 0.5em;
  }

  & tbody tr:nth-child(odd) {
    background-color: rgba(223, 223, 223, 0.3);
  }

  & th {
    border-bottom: 1px solid var(--c-grey-3);
  }
}

.c-table--vidshow {
  & tbody tr:nth-child(odd) {
    background-color: transparent;
  }

  & tbody tr:nth-child(4n + 1) {
    background-color: rgba(255, 255, 255, 0.3);
  }

  & tbody tr:nth-child(4n + 2) {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

@media (--bp-ph) {
  .c-table,
  .c-table thead,
  .c-table tbody,
  .c-table tr {
    display: block;
    text-align: left;
  }

  .c-table thead,
  .c-table th {
    display: none;
  }

  .c-table td {
    display: flex;
    flex-flow: row nowrap;
  }

  .c-table [data-header]::before {
    content: attr(data-header) ": ";
    font-weight: var(--sansFWBold);
    margin-right: 1em;
  }
}
