.t-livestream {
  background: linear-gradient(200deg, #2f5d5a 10%, #193f4e 90%) !important;
  color: #fff;
  width: 100%;
  height: 100%;

  & .c-allynav__toplink {
    text-decoration: underline;
    text-underline-position: under;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
