/* .l-container {
  position: relative;
  width: 100%;
  max-width: 52rem;
  padding: 0 0.5rem;
  margin: 0 auto;
}

@media screen and (min-width: 720px) {
  .l-container {
    padding: 0 2rem;
  }
}

.l-container--flush {
  padding: 0;
}

.l-container--fluid {
  width: 100%;
  max-width: none;
} */
